<ion-grid class="ion-no-padding">
  <ion-row>
    @for (it of hours; track i; let i = $index;) {
      <ion-col
        tappable
        [size]="it.colSize"
        (click)="!it.isDisabled && onSelectTime(it.label)"
        >
        <div class="hours-box" [ngClass]="{ 'color-pink': selectedTime === it.label, disabled: it.isDisabled }">
          {{ it.label }}
        </div>
      </ion-col>
    }
  </ion-row>
</ion-grid>

<div class="flex m-t-4">
  <div class="ion-margin-end flex ion-justify-content-center ion-align-items-center">
    <i class="dot"></i>
    &nbsp;&nbsp;{{'AVAILABLE' | translate}}
  </div>

  <div class="ion-margin-end flex ion-justify-content-center ion-align-items-center">
    <i class="dot medium"></i>
    &nbsp;&nbsp;{{'BOOKED' | translate}}
  </div>
</div>
