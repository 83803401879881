import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { FormEffects } from '../../store';

export const loginFormName = 'login';

@Injectable()
export class LoginEffects {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly formEffects: FormEffects,
    ) {}

    public readonly $login = this.formEffects.getSendFormEffect(loginFormName, formState => {
        return this.authService.login(formState.formData).pipe(
            tap((): void => {
                if (this.router.url === '/login') {
                    void this.router.navigate(['/home']);
                }
            }),
        );
    });
}
