import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LocalizedCurrencyPipe } from './pipes/localized-currency.pipe';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        ScrollingModule,
        NgOptimizedImage,
        LocalizedCurrencyPipe,
    ],
    providers: [CurrencyPipe],
})
export class SharedModule {}
