import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
    providedIn: 'root',
})
export class ModeService {
    public paletteToggle = false;

    public async loadThemePreference(): Promise<void> {
        const { value } = await Preferences.get({ key: 'dark-mode' });

        if (value !== null) {
            // If there's a saved preference, use it
            this.initializeDarkPalette(value === 'true');
        } else {
            // Otherwise, use the system preference
            // const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
            // listen for system theme changes
            // prefersDark.addEventListener('change', mediaQuery => this.initializeDarkPalette(mediaQuery.matches));

            this.initializeDarkPalette(true); // default dark theme
        }
    }

    // Check/uncheck the toggle and update the palette based on isDark
    public initializeDarkPalette(isDark: boolean): void {
        this.paletteToggle = isDark;
        this.toggleDarkPalette(isDark);
    }

    // Listen for the toggle check/uncheck to toggle the dark palette
    public async toggleChange(ev: any): Promise<void> {
        const isChecked = ev.detail.checked;
        this.toggleDarkPalette(isChecked);
        await Preferences.set({ key: 'dark-mode', value: isChecked.toString() });
    }

    // Add or remove the "ion-palette-dark" class on the html element
    public toggleDarkPalette(shouldAdd: boolean): void {
        document.documentElement.classList.toggle('ion-palette-dark', shouldAdd);
    }
}
