import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonicModule, IonTextarea, ModalController, Platform } from '@ionic/angular';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass } from '@angular/common';
import { IAppState, ILoadDataState, IMessage, MessagesWsService, timeoutUtil } from '@solar/core/src';
import { Store } from '@ngrx/store';
import { addMessage, getUser, loadMessages, LoadPage, LoadPageSuccess, loadUnread, selectPageByName } from '@solar/core/src/store';
import { TranslateModule } from '@ngx-translate/core';
import { ChatModalListComponent } from '../chat-modal-list/chat-modal-list.component';
import { distinctUntilChanged, filter, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { Components } from '@ionic/core';
import IonContent = Components.IonContent;
import { ActivatedRoute, RouterLink } from '@angular/router';
import { debounceTime, take } from 'rxjs/operators';
import { Keyboard } from '@capacitor/keyboard';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { HeaderComponent } from '../../../components/stateless/header/header.component';
import { FormConnectDirective, IsAuthorizedDirective, ShowExceptMeDirective } from '../../../directives';
import { AvatarComponent, IAvatar } from '../../../components/stateless/avatar/avatar.component';
import { FullNamePipe } from '@solar/shared/pipes';

@Component({
    selector: 'hb-chat-modal',
    templateUrl: './chat-modal.component.html',
    styleUrls: ['./chat-modal.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        FormsModule,
        NgClass,
        ReactiveFormsModule,
        TranslateModule,
        ChatModalListComponent,
        HeaderComponent,
        ShowExceptMeDirective,
        IsAuthorizedDirective,
        FormConnectDirective,
        AvatarComponent,
        FullNamePipe,
        RouterLink,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatModalComponent implements OnInit, OnDestroy {
    private readonly activatedRoute = inject(ActivatedRoute);

    public formName: string = addMessage;

    public form: FormGroup;

    @ViewChild('content') private readonly content: IonContent;
    @ViewChild('textarea') private readonly textarea: IonTextarea;

    @Input() public categoryId: number;
    @Input() public chatMember: IAvatar;

    protected subscription = new Subscription();

    protected unread$: Observable<ILoadDataState<any>> = this.store.select(selectPageByName(loadUnread));

    protected typingSignal = this.webSocketService.typingMessageSignal;

    public isTyping = false;
    private readonly destroy$ = new Subject<void>();

    private isEventProcessed = false;

    public scrollPercentage = 0;

    constructor(
        private readonly modalCtrl: ModalController,
        private readonly fb: FormBuilder,
        private readonly store: Store<IAppState>,
        private readonly webSocketService: MessagesWsService,
        private readonly platform: Platform,
        private readonly authService: AuthService,
        private readonly ch: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
    ) {
        effect(
            () => {
                if (this.webSocketService.sendMessageSignal()) {
                    this.scrollToBottomOnInit();
                    this.store.dispatch(LoadPage({ path: loadUnread }));
                }
            },
            { allowSignalWrites: true },
        );
    }

    public async cancel(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    public ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.chatMember = JSON.parse(params['chatMember']);
        });

        this.form = this.fb.group({
            receiver: [null, Validators.required],
            message: [null, Validators.required],
        });

        this.categoryId = this.activatedRoute.snapshot.params['userId'];

        this.webSocketService.connectUser();

        this.checkUnread();

        this.listenKeyboard();

        const valueChanges$ = this.form
            .get('message')
            .valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$));

        valueChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (!this.isTyping) {
                this.isTyping = true;
                this.sendTypingInfo();
                this.ch.detectChanges();
            }
        });

        valueChanges$.pipe(debounceTime(3000), takeUntil(this.destroy$)).subscribe(() => {
            this.isTyping = false;
            this.webSocketService.addTyping(this.categoryId, null);
            this.ch.detectChanges();
        });
    }

    public ionViewWillEnter(): void {
        this.form.get('receiver').setValue(this.categoryId);

        void timeoutUtil(100).then(() => {
            this.scrollToBottomOnInit();
        });
    }

    private sendTypingInfo(): void {
        this.store
            .select(selectPageByName(getUser))
            .pipe(take(1))
            .subscribe(user => {
                if (!user) {
                    this.store.dispatch(LoadPage({ path: getUser, reqData: { id: this.authService?.principal?.id } }));
                    return;
                }

                this.webSocketService.addTyping(this.categoryId, user.data);
            });
    }

    public onSubmit(): void {
        void this.textarea.setFocus();
    }

    private listenKeyboard(): void {
        if (this.platform.is('capacitor')) {
            void Keyboard.addListener('keyboardDidShow', () => {
                this.scrollToBottomOnInit();
            });

            void Keyboard.addListener('keyboardDidHide', () => {
                this.scrollToBottomOnInit();
            });
        }
    }

    public onSuccessForm(msg): void {
        this.store
            .select(selectPageByName(loadMessages))
            .pipe(take(1))
            .subscribe(res => {
                if (msg.receiver !== this.authService?.principal?.id) {
                    this.store.dispatch(LoadPageSuccess({ path: loadMessages, data: { data: [...res.data, msg] } }));
                }

                this.webSocketService.sendMessageSignal.set(res);
            });

        this.form.get('message').setValue('');
        this.webSocketService.addTyping(this.categoryId, null);
    }

    public scrollToBottomOnInit(): void {
        if (this.content) {
            void this.content.scrollToBottom();
        }
    }

    protected checkUnread(): void {
        this.unread$
            .pipe(
                filter((src: ILoadDataState<IMessage[]>) => !!src?.data?.length && !src?.isPending && src?.hasLoaded),
                takeUntil(this.destroy$),
            )
            .subscribe(res => {
                const data = res?.data?.filter(it => +it.sender === +this.categoryId);
                if (data?.length && !this.isEventProcessed) {
                    this.isEventProcessed = true;
                    this.webSocketService.setMessagesRead(res.data);
                    this.store.dispatch(LoadPage({ path: loadUnread }));
                }
            });
    }

    public async contentScrolled(ev): Promise<void> {
        const scrollElement = await this.content.getScrollElement();
        const scrollPosition = ev.detail.scrollTop;
        const totalContentHeight = scrollElement.scrollHeight;

        this.scrollPercentage = scrollPosition / (totalContentHeight - ev.target.clientHeight) + 0.001;
    }

    public ngOnDestroy(): void {
        this.isEventProcessed = false;
        this.form.reset();
        this.subscription.unsubscribe();

        this.destroy$.next();
        this.destroy$.complete();
    }
}
