export function isEmptyData(data: any): boolean {
    const isEmptyArray = Array.isArray(data) && data.length === 0;
    const isEmptyVariable = !data;

    return isEmptyArray || isEmptyVariable;
}

export function isEmptyValue(value: any): boolean {
    return value === null || value === undefined || value === 'null' || value === 'undefined';
}

export const isFunction: (val) => boolean = val => typeof val === 'function';
