import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { HTTP_BASE_URL, OnlineWsService } from '@solar/core/src';
import { NgClass } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UserRes } from '@solar/core/src/store';

export interface IAvatar extends UserRes {
    user_id?: number;
}

@Component({
    selector: 'hb-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IonicModule, NgClass],
})
export class AvatarComponent implements OnInit {
    @Input() public data: IAvatar;
    @Input() public slot: 'start' | 'end' = 'start';

    @Input() public size: 'large' | 'medium' | 'small' | 'extra-small';

    public initials: string;

    constructor(
        @Inject(HTTP_BASE_URL) protected readonly httpBaseUrl: string,
        protected onlineUsers: OnlineWsService,
    ) {}

    public ngOnInit(): void {
        this.createInitials();
    }

    private createInitials(): void {
        let initials = '';
        const firstName = this.data.first_name?.trim() ?? '';
        const lastName = this.data.last_name?.trim() ?? '';

        // Add initial from first name
        if (firstName.length > 0) {
            initials += firstName.charAt(0).toUpperCase();
        }

        // Add initial from last name if it exists
        if (lastName.length > 0) {
            initials += lastName.charAt(0).toUpperCase();
        }

        // If last name doesn't exist, return initials from first name only
        if (lastName.length === 0 && firstName.length > 0) {
            initials = firstName.charAt(0).toUpperCase();
        }

        this.initials = initials;
    }
}
