import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { MasterInfo, UserRes } from '../../../store';
import { ICollectionResponse } from '../../../interfaces';
import { UserRolesEnum } from '../../../enums';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private readonly http: HttpClient) {}

    public getUser(userId: number): Observable<UserRes> {
        return this.http.get<UserRes>(`users/${userId}`);
    }

    public updateUser(body: UserRes): Observable<UserRes> {
        const formData: FormData = new FormData();

        formData.append('role', String(body.role));

        return this.http.patch<UserRes>(`users/${body.id}`, formData);
    }

    public requestPasswordReset(body: { code: string; phone: string }): Observable<UserRes> {
        return this.http.post<UserRes>(`request-password-reset`, body);
    }

    public passwordReset(body: { phone: string; code: string; password: string }): Observable<UserRes> {
        return this.http.post<UserRes>(`password-reset`, body);
    }

    public deleteAccount(): Observable<Object> {
        return this.http.delete(`users`);
    }

    public getMasters(options?: { is_active: boolean }): Observable<ICollectionResponse<MasterInfo>> {
        return this.http.get(`users`, { params: { role: UserRolesEnum.MASTER, ...options } });
    }

    public changePassword(body: { new_password: string; current_password: string }): Observable<UserRes> {
        return this.http.post<UserRes>(`change-password`, body);
    }

    public async searchUsersAsync(query: string): Promise<UserRes[] | null> {
        try {
            return await lastValueFrom(this.http.get<UserRes[]>('users/search', { params: { query } }));
        } catch (e) {
            console.error('Error occurred while searching for users:', e);
            return null;
        }
    }

    public getUsers(options?: { is_active?: boolean; role?: UserRolesEnum; page?: number }): Observable<ICollectionResponse<MasterInfo>> {
        return this.http.get(`users`, { params: options });
    }
}
