import { ChangeDetectorRef, Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { getUser, selectPageByName } from '@solar/core/src/store';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '@solar/core/src';

import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[isAuthorized]',
    standalone: true,
})
export class IsAuthorizedDirective implements OnInit, OnDestroy {
    private readonly onDestroy = new Subject<void>();

    @Input() public isAuthorized: boolean | string = true; // Default value is true

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly store: Store<IAppState>,
        private readonly viewContainer: ViewContainerRef,
        private readonly ch: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.store
            .select(selectPageByName(getUser))
            .pipe(takeUntil(this.onDestroy))
            .subscribe(res => {
                this.viewContainer.clear();

                if ((this.isAuthorized && res?.data) || (!this.isAuthorized && !res?.data)) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }

                this.ch.detectChanges();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
