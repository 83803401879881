<ion-header>
  <hb-header>
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()">{{'CANCEL' | translate}}</ion-button>
    </ion-buttons>
    <ion-title>{{ title | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirmChanges()">{{'DONE' | translate}}</ion-button>
    </ion-buttons>
  </hb-header>
</ion-header>

<ion-content class="ion-padding">
  <ion-list mode="ios" [inset]="true" class="ion-no-margin margin-no">
    <ion-list-header color="light">
      <ion-label class="ion-margin-top coco-gothic font-size-18">{{"CREDIT_AND_DEBIT_CARDS" | translate}}</ion-label>
    </ion-list-header>
    <ion-radio-group (ionChange)="checkboxChange($event)" [value]="selectedItem">
      @for (it of (paymentsList$ | async)?.data; track trackItems($index, it)) {
        <ion-item
          color="light"
          (click)="onSelected({icon: it?.card?.brand, value: it?.id, text: '**** **** **** ' + it?.card?.last4, payment_method: it?.id})"
          >
          <ion-button size="small" slot="start" color="dark">
            <ion-icon slot="icon-only" [src]="'assets/icons/payments/' + (it?.card?.brand | lowercase) + '.svg'"></ion-icon>
          </ion-button>
          <ion-radio [value]="it?.id">
            {{(it?.card?.brand | titlecase)}}<br/>
            **** **** **** {{it?.card.last4}}
          </ion-radio>
        </ion-item>
      }

      <ion-item color="light" (click)="onSelected({icon: 'card-outline', value: SelectedPaymentEnum.CARD, text: 'CARD'})">
        <ion-button size="small" slot="start" color="dark">
          <ion-icon slot="icon-only" [name]="'add-outline'"></ion-icon>
        </ion-button>

        <ion-radio value="new_card">
          {{'NEW_CARD' | translate}}
        </ion-radio>
      </ion-item>

      @if (otherPaymentMethods) {
        <ion-list-header color="light">
          <ion-label class="ion-margin-top coco-gothic font-size-18">{{"OTHER_METHODS" | translate}}</ion-label>
        </ion-list-header>
        @for (item of items; track trackItems($index, item)) {
            <ion-item color="light" (click)="onSelectedByValue(item.value)" class="ion-activatable ripple-parent rounded-rectangle">
              <ion-button size="small" slot="start" color="dark">
                <ion-icon slot="icon-only" [name]="item.icon"></ion-icon>
              </ion-button>
              <ion-radio [value]="item.value">
                {{item.text | translate}}
              </ion-radio>
              <ion-ripple-effect></ion-ripple-effect>
            </ion-item>
        }
      }
    </ion-radio-group>
  </ion-list>
</ion-content>
