import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadBatch } from './effects';
import { IAppState } from '@solar/core/src';
import { LoadBatchPage } from '@solar/core/src/store';

@Injectable({
    providedIn: 'root',
})
export class BatchDispatcherService {
    constructor(private readonly store: Store<IAppState>) {}

    public dispatch(reqData: { id: string; url: string; method: string; params?: any }[]): void {
        this.store.dispatch(
            LoadBatchPage({
                path: loadBatch,
                reqData,
            })
        );
    }
}
