import { Injectable } from '@angular/core';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClearPage, LoadPage, LoadPageError, LoadPagePush, LoadPagePushSuccess, LoadPageSuccess } from './actions';
import { Observable, of } from 'rxjs';

@Injectable()
export class PagesEffects {
    public getLoadPageEffect(path, makeRequestFn: (action?: any) => Observable<any>) {
        return createEffect(() =>
            this.actions$.pipe(
                ofType(LoadPage),
                filter((action: any) => action.path === path),
                switchMap((action: any) => {
                    return makeRequestFn(action).pipe(
                        map(data => {
                            localStorage.setItem(path, JSON.stringify(data));

                            return LoadPageSuccess({ path, data });
                        }),
                        catchError(({ error }) => {
                            const item = localStorage.getItem(path);
                            const storage = item ? JSON.parse(item) : undefined;

                            return of(LoadPageError({ path, error, data: storage ? storage.data : null }));
                        }),
                    );
                }),
            ),
        );
    }

    public getLoadPagePushEffect(path, makeRequestFn: (page) => Observable<any>) {
        return createEffect(() =>
            this.actions$.pipe(
                ofType(LoadPagePush),
                filter((action: any) => action.path === path),
                switchMap((page: any) => {
                    return makeRequestFn(page).pipe(
                        map(({ data, page, pages }) => LoadPagePushSuccess({ path, data, page, pages })),
                        catchError(({ error }) => of(LoadPageError({ path, error }))),
                    );
                }),
            ),
        );
    }

    public clearPage$: Observable<{ path: string }> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ClearPage),
                tap(state => localStorage.removeItem(state.path)),
            ),
        { dispatch: false },
    );

    constructor(private readonly actions$: Actions) {}
}
