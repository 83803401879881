import { Injectable } from '@angular/core';
import { addServiceFormName, categoryServicesPageName, editServiceFormName } from './selectors';
import { PagesEffects } from '../pages';
import { FormEffects } from '../forms';
import { CategoryServicesService } from '../../services/rest/category-services';
import { map } from 'rxjs/operators';
import { CategoryServicesRes } from '../../store';

@Injectable()
export class CategoryServicesEffects {
    public readonly $addService = this.formEffects.getSendFormEffect(addServiceFormName, (formState: { formData: CategoryServicesRes }) => {
        return this.categoryServices.addService(formState.formData);
    });

    public readonly $editService = this.formEffects.getSendFormEffect(editServiceFormName, (formState: { formData: CategoryServicesRes }) =>
        this.categoryServices.editService(formState.formData),
    );

    public readonly loadCategoriesServices$ = this.pagesEffects.getLoadPageEffect(
        categoryServicesPageName,
        (req: { reqData: { masterId: number; type?: string } }) =>
            this.categoryServices.getAll(req.reqData.masterId, req.reqData.type).pipe(
                map(res => {
                    return { data: res.data.map(it => ({ ...it, isChecked: false })) };
                }),
            ),
    );

    constructor(
        private readonly pagesEffects: PagesEffects,
        private readonly formEffects: FormEffects,
        private readonly categoryServices: CategoryServicesService,
    ) {}
}
