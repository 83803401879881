import { enableProdMode, LOCALE_ID, APP_INITIALIZER, importProvidersFrom, Injector } from '@angular/core';

import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { defineCustomElements as defineCustomElementsStripe } from 'stripe-pwa-elements/loader';
import { AppComponent } from './app/app.component';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from '@solar/shared/shared.module';
import { HamboCoreModule } from '@solar/core/src/hambo-core.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { withInterceptorsFromDi, provideHttpClient, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { routes } from './app/app.routes';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { provideImgixLoader, NgOptimizedImage, registerLocaleData } from '@angular/common';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AppInitFactory, AppInitService } from '@solar/core/src/services/app-init';
import { OrdersService } from './app/core/services';
import { IonicModule } from '@ionic/angular';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import {
    BaseUrlInterceptor,
    ErrorsMapInterceptor,
    HTTP_BASE_URL,
    HTTP_PARAMS_CONFIG,
    HTTP_STORAGE_TOKEN_KEY,
    HttpParamsInterceptor,
    JsonInterceptor,
    StorageTokenInterceptor,
} from '@solar/core/src';
import { NoopInterceptor } from '@solar/core/src/interceptors/noop.interceptor';
import { IonicRouteStrategy } from '@ionic/angular/standalone';
// import * as Sentry from '@sentry/angular';

export const interceptorsConfig: any = {
    baseUrl: environment.api,
    storageTokenKey: 'token',
};

if (environment.production) {
    enableProdMode();
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEn, 'en');

// Sentry.init({
//     dsn: environment.sentryUrl,
//     release: packageInfo.version,
//     integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//     // Performance Monitoring
//     tracesSampleRate: 0.1, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ['localhost', /^https:\/\/hey-beauty\.app\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            EffectsModule.forRoot([]),
            StoreModule.forRoot({}),
            StoreDevtoolsModule.instrument(),
            BrowserModule,
            IonicModule.forRoot({
                scrollAssist: false,
                scrollPadding: false,
            }),
            TranslateModule.forRoot({
                defaultLanguage: 'en',
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
            HamboCoreModule,
            SharedModule,
            NgOptimizedImage,
            ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
            AngularFireModule.initializeApp(environment.firebase),
            AngularFireMessagingModule,
            AngularFireAuthModule,
        ),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth()),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        OrdersService,
        { provide: LOCALE_ID, useValue: 'en' },
        // {
        //     provide: ErrorHandler,
        //     useValue: Sentry.createErrorHandler({
        //         showDialog: false,
        //     }),
        // },
        // {
        //     provide: Sentry.TraceService,
        //     deps: [Router],
        // },
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitFactory,
            deps: [AppInitService], // [AppInitService, Sentry.TraceService]
            multi: true,
        },
        AngularFireAuth,
        provideImgixLoader(environment.api),
        {
            provide: HTTP_BASE_URL,
            useValue: interceptorsConfig.baseUrl,
        },
        {
            provide: HTTP_STORAGE_TOKEN_KEY,
            useValue: interceptorsConfig.storageTokenKey,
        },
        {
            provide: HTTP_PARAMS_CONFIG,
            useValue: interceptorsConfig.httpParamsFactory,
        },
        {
            provide: HTTP_INTERCEPTORS,
            // use json by default
            useClass: JsonInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: interceptorsConfig.baseUrl ? BaseUrlInterceptor : NoopInterceptor,
            deps: [HTTP_BASE_URL],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: interceptorsConfig.storageTokenKey ? StorageTokenInterceptor : NoopInterceptor,
            deps: [HTTP_STORAGE_TOKEN_KEY],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: interceptorsConfig.httpParamsFactory ? HttpParamsInterceptor : NoopInterceptor,
            deps: [HTTP_PARAMS_CONFIG],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorsMapInterceptor,
            multi: true,
            deps: [Injector],
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(routes),
    ],
})
    .then(() => void defineCustomElements(window), void defineCustomElementsStripe(window))
    .catch(err => console.log(err));
