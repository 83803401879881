import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRoleService } from "@solar/core/src/services/user-role/user-role.service";

@Injectable({
  providedIn: 'root',
})
export class RouteToAdminGuard implements CanActivate {
  constructor(private userRoleService: UserRoleService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (this.userRoleService.isAdmin) {
      // Получаем параметры из запроса
      const queryParams = route.queryParams;
      // Перенаправляем на /admin с теми же параметрами
      this.router.navigate(['/admin'], { queryParams });
      return false;
    }

    return true; // Если не админ, продолжаем навигацию к /orders
  }
}
