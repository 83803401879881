import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addDescriptionsFormName, descriptionsPageName, editDescriptionsFormName, loadDescription } from './selectors';
import { PagesEffects } from '../pages';
import { FormEffects } from '../forms';
import { map } from 'rxjs/operators';
import { DescriptionsService } from '../../services';
import { IAppState } from '../../interfaces';

@Injectable()
export class DescriptionsEffects {
    public readonly $add = this.formEffects.getSendFormEffect(addDescriptionsFormName, formState => {
        return this.descriptionsService.add(formState.formData);
    });

    public readonly $edit = this.formEffects.getSendFormEffect(editDescriptionsFormName, formState => {
        return this.descriptionsService.edit(formState.formData);
    });
    public readonly loadAll$ = this.pagesEffects.getLoadPageEffect(descriptionsPageName, ({ reqData }) =>
        this.descriptionsService.loadAll(reqData)
    );

    public readonly loadCategory$ = this.pagesEffects.getLoadPageEffect(loadDescription, ({ reqData }) => {
        return this.descriptionsService.loadOne(reqData.id).pipe(
            map(res => {
                return { data: res };
            })
        );
    });

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<IAppState>,
        private readonly pagesEffects: PagesEffects,
        private readonly formEffects: FormEffects,
        private readonly descriptionsService: DescriptionsService
    ) {}
}
