import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[blurredImageLoader]',
    standalone: true,
})
export class BlurredImageLoaderDirective implements AfterViewInit {
    constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}

    public ngAfterViewInit(): void {
        const blurredImageDiv = this.elementRef.nativeElement;
        const img = blurredImageDiv.querySelector('img');

        this.applyStyles(blurredImageDiv, img);

        if (img && img.complete) {
            this.loaded(blurredImageDiv, img);
        } else if (img) {
            img.addEventListener('load', () => this.loaded(blurredImageDiv, img));
        }
    }

    private applyStyles(blurredImageDiv: HTMLElement, img: HTMLImageElement | null): void {
        this.renderer.setStyle(blurredImageDiv, 'position', 'relative');
        this.renderer.setStyle(blurredImageDiv, 'overflow', 'hidden');
        this.renderer.setStyle(blurredImageDiv, 'background-repeat', 'no-repeat');
        this.renderer.setStyle(blurredImageDiv, 'background-size', 'cover');

        if (img) {
            const overlay = this.renderer.createElement('div');
            this.renderer.setStyle(overlay, 'position', 'absolute');
            this.renderer.setStyle(overlay, 'top', '0');
            this.renderer.setStyle(overlay, 'right', '0');
            this.renderer.setStyle(overlay, 'bottom', '0');
            this.renderer.setStyle(overlay, 'left', '0');
            this.renderer.setStyle(overlay, 'opacity', '0');
            this.renderer.setStyle(overlay, 'animation', 'pulse 2.5s infinite');
            this.renderer.setStyle(overlay, 'backgroundColor', 'var(--text-color)');
            this.renderer.appendChild(blurredImageDiv, overlay);

            this.renderer.setStyle(img, 'opacity', '0');
            this.renderer.setStyle(img, 'transition', 'all 250ms ease-in-out');
            this.renderer.setStyle(img, 'transform', 'scale(1.3)');
        }
    }

    private loaded(blurredImageDiv: HTMLElement, img: HTMLImageElement): void {
        blurredImageDiv.classList.add('loaded');
        this.renderer.setStyle(img, 'opacity', '1');
        this.renderer.setStyle(img, 'transform', 'scale(1)');
        this.renderer.setStyle(img, 'z-index', '1');
        this.renderer.setStyle(img, 'position', 'static');
    }
}
