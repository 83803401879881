import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UrlMasks } from '../../../enums';
import { ICollectionResponse } from '../../../interfaces';

export interface IMessage {
    id: number;
    message: string;
    created_at: string;
    is_read: boolean;
    receiver: number;
    sender: number;
    user_info?: { id: number; name: string; avatar: string };
}

@Injectable({
    providedIn: 'root',
})
export class MessagesService {
    constructor(private readonly http: HttpClient) {}

    public getAll(params: { receiver: number; page: number }): Observable<ICollectionResponse<IMessage[]>> {
        const principal: { id: string } = JSON.parse(localStorage.getItem('principal'));

        return this.http.get<ICollectionResponse<IMessage[]>>(`messages?receiver=${params.receiver}&sender=${principal.id}`, { params });
    }

    public getAllUnread(): Observable<ICollectionResponse<IMessage[]>> {
        const principal: { id: string } = JSON.parse(localStorage.getItem('principal'));

        if (!principal?.id) {
            return of();
        }

        return this.http.get<ICollectionResponse<IMessage[]>>(`messages-unread?receiver=${principal.id}`);
    }

    public getAllDirects(): Observable<any> {
        return this.http.get(`messages/${UrlMasks.UserId}`);
    }

    public create(sendData: { receiver: number; message: string }): Observable<any> {
        const principal = JSON.parse(localStorage.getItem('principal'));

        const data = {
            ...sendData,
            sender: principal.id,
        };

        return this.http.post(`messages`, data);
    }

    public deleteItem(itemId: number): Observable<number> {
        return this.http.delete<number>(`messages/${itemId}`);
    }

    public deleteAllMessages(user_id: number): Observable<number> {
        return this.http.delete<number>(`messages/all/${user_id}`);
    }
}
