import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { UserRolesEnum } from '../enums/user-roles.enum';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
    ) {}

    public canActivate(): boolean {
        const principal = this.authService?.principal;

        if (principal && principal.role === UserRolesEnum.ADMIN) {
            return true;
        } else {
            console.warn('Unauthorized access attempt by non-admin user.');
            void this.router.navigate(['/']);
            return false;
        }
    }
}
