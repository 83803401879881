import { Component, ContentChild } from '@angular/core';
import { IonicModule, IonInput } from '@ionic/angular';

import { FormsModule } from '@angular/forms';

@Component({
    selector: 'hb-show-hide-password',
    templateUrl: './show-hide-password.component.html',
    styleUrls: ['./show-hide-password.component.scss'],
    standalone: true,
    imports: [FormsModule, IonicModule],
})
export class ShowHidePasswordComponent {
    public showPassword = false;
    @ContentChild(IonInput) private readonly input: IonInput;
    constructor() {}

    public toggleShow(): void {
        this.showPassword = !this.showPassword;
        this.input.type = this.showPassword ? 'text' : 'password';
    }
}
