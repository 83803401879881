import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UserRes } from '@solar/core/src/store';
import { ServerTranslatePipe } from '@solar/shared';
import { TitleCasePipe, NgClass } from '@angular/common';
import { isEmptyData } from '@solar/core/src/services/utils';

@Component({
    selector: 'hb-user-services',
    templateUrl: './user-services.component.html',
    styleUrls: ['./user-services.component.scss'],
    standalone: true,
    imports: [IonicModule, TitleCasePipe, ServerTranslatePipe, NgClass],
})
export class UserServicesComponent {
    @Input() public user: UserRes;
    @Input() public color: 'light' | 'medium' | 'dark' = 'medium';
    @Input() public ngClass: string;

    protected readonly isEmptyData = isEmptyData;
}
