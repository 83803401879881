import { Injectable } from '@angular/core';
import { FormEffects } from '../../store';
import { UserService } from '../../services/rest/user';

export const passwordResetFormName = 'passwordResetFormName';
export const passwordNewFormName = 'passwordNewFormName';
export const changePassword = 'changePassword';

@Injectable()
export class PasswordResetEffects {
    constructor(
        private readonly userService: UserService,
        private readonly formEffects: FormEffects,
    ) {}

    public readonly $passwordResetRequest = this.formEffects.getSendFormEffect(passwordResetFormName, formState => {
        return this.userService.requestPasswordReset(formState.formData);
    });

    public readonly $passwordReset = this.formEffects.getSendFormEffect(passwordNewFormName, formState => {
        return this.userService.passwordReset(formState.formData);
    });

    public readonly $changePassword = this.formEffects.getSendFormEffect(changePassword, formState => {
        return this.userService.changePassword(formState.formData);
    });
}
