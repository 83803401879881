@if (orders.isPending) {
  <div class="ion-padding flex ion-justify-content-center">
    <ion-spinner color="pink" name="lines"></ion-spinner>
  </div>
}

@if (!orders.isPending && orders?.data?.length) {
  <ion-list class="ion-padding-top ion-padding-horizontal" lines="none" mode="md">
    @for (it of orders.data; track trackByFn($index, it)) {
      <ion-item-sliding
        #itemSliding class="order-list-item"
        >
        <ion-item-options side="start" [id]="it.id" *showOnlyFor="[UserRolesEnum.MASTER, UserRolesEnum.ADMIN]">
          <ion-item-option>
            <ion-icon size="large" name="chatbox-ellipses-outline"></ion-icon>
          </ion-item-option>
        </ion-item-options>

        <hb-admin-orders-list-item (menuEvent)="onMenu(it)" (search)="search.emit($event)" [it]="it"></hb-admin-orders-list-item>

        <hb-message-modal [order]="it" (res)="itemSliding.close()"></hb-message-modal>

      </ion-item-sliding>
    }
  </ion-list>
  <ion-infinite-scroll (ionInfinite)="onIonInfinite($event, orders.page, orders.pages)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
}

@if (!orders.isPending && !orders?.data?.length) {
  <ion-card class="flex w100 ion-justify-content-center ion-no-margin">
    <ion-card-content class="ion-text-center">
      <div>
        <ion-icon size="large" name="file-tray-stacked-outline"></ion-icon>
      </div>
      {{'NO_DATA' | translate}}
    </ion-card-content>
  </ion-card>
}

<ion-modal mode="ios" [isOpen]="isModalOpen" [initialBreakpoint]="0.7" [breakpoints]="[0, 0.7, 1]" (ionModalDidDismiss)="setOpen(false)">
  <ng-template>
    <hb-payments-list-modal
      class="ion-page"
      title="PAYMENT_METHODS"
      [items]="fruits"
      [selectedItem]="selectedFruits"
      (selectionChange)="paymentSelectionChange($event)"
      (selectionCancel)="setOpen(false)"
    ></hb-payments-list-modal>
  </ng-template>
</ion-modal>
