<ion-item class="hb-order-item">
  <ion-label class="ion-text-wrap ion-margin-vertical">
    <div class="font-size-16 m-b-8 flex-row ion-justify-content-between font-weight-500">
      <div>
        <div (click)="writeToClipboard(it.name)" class="flex ion-align-items-center">
          {{ it.name }}
          <ion-text color="medium" class="font-size-12">&nbsp;•&nbsp;{{it.id}}</ion-text>
        </div>
        @if (it?.total_orders) {
          <div class="font-size-11">
            <ion-buttons class="ion-no-margin btn" mode="ios" (click)="onSearch(it.phone)">
              <ion-button class="btn-inner">
                <ion-text color="medium">{{'NUMBER_OF_ORDERS' | translate}}:</ion-text>&nbsp;
                {{it.total_orders}}
              </ion-button>
            </ion-buttons>
          </div>
        }
      </div>
      <div (click)="writeToClipboard(it.phone)">{{ it.phone }}</div>
    </div>

    <div class="font-size-14 m-b-8">{{ it.date | date : 'mediumDate' : 'UTC' }}
      , {{ it.hours && sumServicesTimes(it.hours) }}
    </div>

    <div class="font-size-14 m-b-8">
      <div class="m-b-8">
        {{ it | serverTranslate }}@if (it.price && +it.price > 0) {
        <span class="font-size-14">: <span
        class="font-weight-500">{{ it.price | localizedCurrency:it?.currency:'symbol':it.price_to  }}</span></span>
      }
    </div>

    @if (it.rent_time) {
      <div>{{ ('DATE_RENT' | translate) + ': ' + it.rent_time + ' ' + ('HOURS' | translate)}}</div>
    }
  </div>

  @if (it.wishes) {
    <div class="font-size-14 m-b-8 m-t-8">{{'WISHES' | translate}}: {{ it.wishes }}</div>
  }

  <div class="flex-row">
    @if (it.master) {
      <div class="font-size-14 flex-row ion-align-items-center">
        <hb-avatar size="small" slot="start" [data]="it.master"></hb-avatar>
        <div class="m-l-10">
          {{ it.master | serverTranslate : 'first_name' }}
        </div>
      </div>
    }

      <div class="flex">
        <ion-chip
          [color]="statusColor(it.order_status_id)"
          class="ion-no-margin white-space-no-wrap"
          mode="ios">
          {{statuses[it.order_status_id] | translate}}
        </ion-chip>

        <ion-buttons (click)="menuEvent.emit(it)" class="m-l-8" color="pink" mode="ios">
          <ion-button>
            <ion-icon slot="icon-only" icon="ellipsis-horizontal"></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
  </div>
</ion-label>
</ion-item>
