import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IFormState } from '../core-store.interface';

export const AddForm = createAction('[FORM] Add Form', props<{ formName: any; formData?: any }>());

export const SendForm = createAction('[FORM] Send', props<{ formName: string; formState: IFormState }>());
export const SendFormSuccess = createAction('[FORM] Send Success', props<{ formName: string; responseData: any }>());
export const SendFormError = createAction('[FORM] Send Error', props<{ formName: string; error: HttpErrorResponse }>());

export const SetFormData = createAction('[FORM] Set Form Data', props<{ formName: string; formData: any }>());

export const ResetForm = createAction('[FORM] Reset', props<{ formName: string }>());
export const ResetFormError = createAction('[FORM] Error Reset', props<{ formName: string }>());

export const ClearAllForms = createAction('Clear all forms');
