export class MonthObserver {
    private observer: MutationObserver | null = null;

    constructor(
        private readonly master: number[],
        private readonly onMonthChange: (master: number[], monthString: string) => void,
    ) {}

    public init(elementToObserve: Element | null, monthText: Element | null): void {
        if (!elementToObserve || !monthText) {
            return;
        }

        if (this.observer) {
            this.disconnect(); // Отключаем текущего наблюдателя, если он существует
        }

        this.observer = new MutationObserver(() => {
            const parts = monthText.textContent?.split(' ');
            const monthString = parts ? parts.join(' ') : '';

            if (monthString) {
                this.onMonthChange(this.master, monthString);
            }
        });

        const observerConfig = {
            childList: true,
            subtree: true,
        };

        this.observer.observe(elementToObserve, observerConfig);
    }

    public disconnect(): void {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }
}
