import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICollectionResponse } from '../../../interfaces';
import { ICreateOrdersData, IOrders } from './orders.dto';

@Injectable({
    providedIn: 'root',
})
export class OrdersService {
    constructor(private readonly http: HttpClient) {}

    public getAdminOrders(params: { master_id?: number; user_id?: number; page?: number }): Observable<ICollectionResponse<IOrders>[]> {
        return this.http.get<ICollectionResponse<IOrders>[]>(`admin/orders`, { params });
    }

    public getOrder(params: { id: number }): Observable<ICollectionResponse<IOrders>> {
        return this.http.get<ICollectionResponse<IOrders>>(`orders/${params.id}`);
    }

    public getOrders(params: { master_id?: number; user_id?: number; page?: number }): Observable<ICollectionResponse<IOrders>[]> {
        return this.http.get<ICollectionResponse<IOrders>[]>(`orders`, { params });
    }

    public createOrders(body: ICreateOrdersData): Observable<{ message: string }> {
        return this.http.post<{ message: string }>(`orders-multiple`, body);
    }

    public editOrder(body: IOrders): Observable<IOrders> {
        return this.http.patch<IOrders>(`orders`, body);
    }
}
