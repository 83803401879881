import { ControlContainer, ControlValueAccessor, UntypedFormControl, FormControlDirective, AbstractControl } from '@angular/forms';
import { Directive, Injector, Input, ViewChild } from '@angular/core';

@Directive()
export class ControlValueAccessorConnector implements ControlValueAccessor {
    @ViewChild(FormControlDirective, { static: true })
    public formControlDirective!: FormControlDirective;

    @Input()
    public formControl?: UntypedFormControl;

    @Input()
    public formControlName?: string;

    public get control(): AbstractControl<any, any> | null | undefined {
        return this.formControl || (this.formControlName ? this.controlContainer.control?.get(this.formControlName) : undefined);
    }

    constructor(private readonly injector: Injector) {}

    public get controlContainer(): ControlContainer {
        return this.injector.get(ControlContainer);
    }

    public registerOnTouched(fn: any): void {
        if (this.formControlDirective && this.formControlDirective.valueAccessor) {
            this.formControlDirective.valueAccessor.registerOnTouched(fn);
        }
    }

    public registerOnChange(fn: any): void {
        if (this.formControlDirective && this.formControlDirective.valueAccessor) {
            this.formControlDirective.valueAccessor.registerOnChange(fn);
        }
    }

    public writeValue(obj: any): void {
        if (this.formControlDirective && this.formControlDirective.valueAccessor) {
            this.formControlDirective.valueAccessor.writeValue(obj);
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (
            this.formControlDirective &&
            this.formControlDirective.valueAccessor &&
            this.formControlDirective.valueAccessor.setDisabledState
        ) {
            this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
        }
    }
}
