import { Injectable } from '@angular/core';
import { MonthObserver } from '@solar/core/src';

@Injectable({
    providedIn: 'root',
})
export class MonthObserverFactory {
    public createMonthObserver(master: number[], onMonthChange: (master: number[], monthYearString: string) => void): MonthObserver {
        return new MonthObserver(master, onMonthChange);
    }
}
