import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { SendForm, SendFormError, SendFormSuccess } from './actions';
import { IAppState } from '@solar/core/src';

@Injectable()
export class FormEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly solarStore: Store<IAppState>,
    ) {}

    public getSendFormEffect(formName, makeRequestFn: (formState: any, state: any) => Observable<any>) {
        return createEffect(() =>
            this.actions$.pipe(
                ofType(SendForm),
                filter((payload: any) => payload.formName === formName),
                withLatestFrom(this.solarStore),
                switchMap(([{ formState }, state]: any[]) => {
                    return makeRequestFn(formState, state).pipe(
                        map(responseData => SendFormSuccess({ formName, responseData })),
                        catchError(({ error }) => of(SendFormError({ formName, error }))),
                    );
                }),
            ),
        );
    }
}
