import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { addCategoryFormName, CategoriesRes, editCategoryFormName } from '@solar/core/src/store/categories';
import { CardModelsEnum, HTTP_BASE_URL, UserSearchStateService } from '@solar/core/src';
import { TranslateModule } from '@ngx-translate/core';
import { SubmitComponent } from '../submit/submit.component';
import { FormConnectDirective } from '../../../directives/form-connect.directive';
import { AvatarComponent, HeaderComponent } from '../../../components';
import { UserSearchComponent } from '@solar/shared/form-components/statefull/user-search/user-search.component';
import { UserRes } from '@solar/core/src/store';
import { UpperCasePipe } from '@angular/common';

@Component({
    selector: 'hb-add-category',
    templateUrl: './add-category.component.html',
    styleUrls: ['./add-category.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        IonicModule,
        ReactiveFormsModule,
        FormConnectDirective,
        SubmitComponent,
        TranslateModule,
        HeaderComponent,
        UserSearchComponent,
        AvatarComponent,
        UpperCasePipe,
    ],
})
export class AddCategoryComponent implements OnInit {
    public form: FormGroup;

    public formName: string;

    public imagePreviewUrl: ArrayBuffer | string;

    @Input() public category: CategoriesRes;

    @Input({ required: true }) public model: CardModelsEnum = CardModelsEnum.CATEGORY;

    protected managers: number[];

    protected isOpenManagerModal = false;

    constructor(
        private readonly fb: FormBuilder,
        private readonly modalCtrl: ModalController,
        private readonly ch: ChangeDetectorRef,
        @Inject(HTTP_BASE_URL) private readonly httpBaseUrl: string,
        private readonly userSearchStateService: UserSearchStateService,
    ) {}

    public ngOnInit(): void {
        this.form = this.fb.group({
            id: [''],
            title: ['', [Validators.required]],
            title_ru: ['', [Validators.required]],
            img: ['', Validators.required],
            is_active: [false],
            position: ['', Validators.required],
            model: ['', Validators.required],
            managers: [null, Validators.required],
        });

        this.setEditConfigs();
    }

    protected onOpenManagerModal(): void {
        this.isOpenManagerModal = true;
        if (this.category?.managers) {
            this.managers = this.category.managers;
            this.userSearchStateService.items.set(this.managers);
        }
        this.ch.detectChanges();
    }

    public setEditConfigs(): void {
        if (this.category) {
            if (this.category.model) {
                this.model = this.category.model as CardModelsEnum;
            }

            this.formName = editCategoryFormName;

            this.form.patchValue(this.category);

            this.imagePreviewUrl = `${this.httpBaseUrl}/${this.category.img}`;

            if (this.category?.managers) {
                this.managers = this.category.managers;
                this.userSearchStateService.items.set(this.managers);
            }
        } else {
            this.formName = addCategoryFormName;

            this.form.get('model').setValue(this.model);
        }
        this.ch.detectChanges();
    }

    public async onSuccessForm(ev): Promise<void> {
        this.form.reset();

        await this.modalCtrl.dismiss(ev);
    }

    public onSelectFile(ev): void {
        const parent = document.querySelector('.file-upload-wrapper');
        const val: string = ev.target.value.replace(/.*(\/|\\)/, '');
        const file: File = ev.target.files[0];
        const reader: FileReader = new FileReader();

        parent.setAttribute('data-text', val);

        this.form.get('img').setValue(file);
        // POST formData to server using HttpClient

        reader.onloadend = () => {
            this.imagePreviewUrl = reader.result;

            this.ch.detectChanges();
        };

        reader.readAsDataURL(file);
    }
    public async onClose(): Promise<void> {
        await this.modalCtrl.dismiss(false);
    }

    public onDismiss(ev: UserRes[]): void {
        this.isOpenManagerModal = false;

        if (ev?.length) {
            this.managers = ev.map(m => m.id);
        } else {
            this.managers = [];
        }

        this.form.get('managers').setValue(this.managers);
    }
}
