import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FormEffects, PagesEffects } from '../../store';
import { MastersService } from '../../../../../src/app/core/services';
import { OrdersService } from '../../services';
import { IAppState, IRestOptions } from '../../interfaces';

export const createOrdersFormName = 'createOrdersFormName';
export const getOrderPath = 'getOrderPath';
export const getOrdersPathName = 'getOrdersPathName';
export const getAdminOrdersPathName = 'getAdminOrdersPathName';

export const editOrderForm = 'editOrderForm';

@Injectable()
export class OrdersEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly router: Router,
        private readonly store: Store<IAppState>,
        private readonly formEffects: FormEffects,
        private readonly mastersService: MastersService,
        private readonly pagesEffects: PagesEffects,
        private readonly ordersService: OrdersService
    ) {}

    public readonly $createOrders = this.formEffects.getSendFormEffect(createOrdersFormName, formState =>
        this.ordersService.createOrders(formState.formData)
    );

    public readonly loadAdminOrders$ = this.pagesEffects.getLoadPageEffect(getAdminOrdersPathName, ({ reqData }) => {
        return this.ordersService.getAdminOrders(reqData);
    });

    public readonly loadAdminOrdersPush$ = this.pagesEffects.getLoadPagePushEffect(
        getAdminOrdersPathName,
        ({ page, reqData }: IRestOptions) => {
            return this.ordersService.getAdminOrders({ page, ...reqData });
        }
    );

    public readonly loadOrders$ = this.pagesEffects.getLoadPageEffect(getOrdersPathName, ({ reqData }) => {
        return this.ordersService.getOrders(reqData);
    });

    public readonly loadOrdersPush$ = this.pagesEffects.getLoadPagePushEffect(getOrdersPathName, ({ page, reqData }: IRestOptions) => {
        return this.ordersService.getOrders({ page, ...reqData });
    });

    public readonly editOrders$ = this.formEffects.getSendFormEffect(editOrderForm, formState => {
        return this.ordersService.editOrder(formState.formData);
    });

    public readonly loadOrder$ = this.pagesEffects.getLoadPageEffect(getOrderPath, ({ reqData }) => {
        return this.ordersService.getOrder(reqData);
    });
}
