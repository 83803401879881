import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UnauthorizedGuard {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
    ) {}

    public canActivate(): Promise<boolean> {
        const signed = !!this.authService?.principal;

        return new Promise(resolve => {
            if (signed) {
                void this.router.navigate(['/']);
                resolve(false);
            }

            resolve(true);
        });
    }
}
