@if (user?.all_categories && !isEmptyData(user?.all_categories)) {
  <div class="font-size-10 m-t-4 m-b-4">
    <ion-text [color]="color" class="white-space-break" [ngClass]="ngClass">
      @for (c of user?.all_categories; track i; let i = $index; let last = $last) {
        {{(c | serverTranslate) | titlecase }}
        @if (!last) {,}
      }
    </ion-text>
  </div>
}
