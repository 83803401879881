export const environment = {
    api: 'https://hey-beauty.app/api',
    production: true,
    googleMapsApi: 'AIzaSyB0_eq0Bye7IQeXKNXtZj49UgtVgtCd_yo',
    stripePublicKey: 'pk_live_rMPU5H5ZMvVnph5iewjz7Iko00DIDGqpau',
    stripeSecretKey: 'sk_live_bxxFccNbGt9v7w2zqiEe3S9D00ppJTmJHD',
    firebase: {
        apiKey: 'AIzaSyCuVJq1FT3DTswW5uzJ-Sqh0cCrP4ddGXo',
        authDomain: 'heybeauty.firebaseapp.com',
        projectId: 'heybeauty',
        storageBucket: 'heybeauty.appspot.com',
        messagingSenderId: '647092155870',
        appId: '1:647092155870:web:e05f7fcb0c5b016c68594f',
        measurementId: 'G-LY624J87M2',
    },
    sentryUrl: 'https://359ee0beee5846ab6377bd2da429d282@o315567.ingest.us.sentry.io/4507457622310912',
};
