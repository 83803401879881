<ng-template #template>
  <ion-toolbar [class]="toolbarClass + ' sr-header-toolbar'">
    @if (backUrl) {
      <ion-buttons slot="start" class="m-r-10">
        <ion-buttons slot="start">
          <ion-back-button class="back-btn" [defaultHref]="backUrl" [text]="backUrlText | translate"></ion-back-button>
        </ion-buttons>
      </ion-buttons>
    }

    @if (title) {
      <ion-title class="ion-text-center text-s-18 weight-400 ion-no-padding ui-text-secondary padding-v-16">
        {{ (title | translate) }}
      </ion-title>
    }

    <ng-content></ng-content>
  </ion-toolbar>
</ng-template>
