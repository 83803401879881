import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(private readonly authService: AuthService, private readonly router: Router) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isLoggedIn = this.authService.token;

        if (isLoggedIn) {
            return true;
        } else {
            void this.router.navigate(['/login']);
        }
    }
}
