<ion-avatar [slot]="slot" class="hb-avatar margin-h-auto" [ngClass]="size">
    @if (data?.img) {
      <img [src]="this.httpBaseUrl + '/' + data.img" alt="User avatar" />
    }

    @if (!data.img) {
        {{initials}}
    }

    @if (onlineUsers.isOnline(data.id || data?.user_id)) {
      <div class="online-icon font-size-12"></div>
    }
</ion-avatar>

