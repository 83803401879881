import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LoadPage, selectPageByName } from '@solar/core/src/store';
import { categoriesPageName } from '@solar/core/src/store/categories';
import { filter } from 'rxjs';
import { StoreServiceUtil } from '@solar/core/src/services/utils';
import { IAppState } from '@solar/core/src';
import { take } from 'rxjs/operators';
import { ServerTranslatePipe } from '../../../pipes/server-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgClass, TitleCasePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'hb-services-select',
    templateUrl: './services-select.component.html',
    styleUrls: ['./services-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ReactiveFormsModule, IonicModule, AsyncPipe, TitleCasePipe, TranslateModule, ServerTranslatePipe, NgClass],
})
export class ServicesSelectComponent implements OnInit {
    @Input() public form: FormGroup;
    @Input() public controlName: string = 'categories';
    @Input() public multiple: boolean = false;
    @Input() public mode: 'ios' | 'md' = 'md';
    @Output() public onChangeEvent: EventEmitter<void> = new EventEmitter();

    public services$ = this.store.select(selectPageByName(categoriesPageName)).pipe(filter(res => res?.data && res?.data?.length > 0));

    constructor(
        private readonly store: Store<IAppState>,
        private readonly storeUtils: StoreServiceUtil,
    ) {}

    public ngOnInit(): void {
        this.storeUtils.dispatchIfEmpty(`core.pages.${categoriesPageName}.pathState.data`, LoadPage({ path: categoriesPageName }));
    }

    public compareWith(o1, o2): boolean {
        // Проверяем, является ли o1 строкой и содержит ли она запятые
        if (typeof o1 === 'string' && o1.includes(',')) {
            // Разбиваем строку на массив чисел
            const arr1 = o1.split(',').map(Number);
            // Проверяем, есть ли o2 в arr1
            return arr1.includes(o2);
        } else {
            // Преобразуем o1 в число
            const val1 = Number(o1);
            // Преобразуем o2 в число
            const val2 = Number(o2);
            // Сравниваем числа
            return val1 === val2;
        }
    }

    protected onChange(event: any): void {
        const id = event.detail.value;
        this.services$.pipe(take(1)).subscribe(res => {
            const find = res.data.find(it => it.id === id);

            this.onChangeEvent.emit(find);
        });
    }
}
