import { Directive, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

@Directive({
    selector: '[haptic]',
    standalone: true,
})
export class HapticDirective {
    constructor(private readonly platform: Platform) {}

    @HostListener('ionTabsWillChange', ['$event'])
    public async onClick(): Promise<void> {
        if (this.platform.is('ios') && this.platform.is('capacitor')) {
            await Haptics.impact({ style: ImpactStyle.Light });
        }
    }
}
