<div class="ion-padding flex ion-justify-content-center">
  <ion-button shape="round" id="open-modal" mode="ios" color="medium" size="medium" fill="outline">
    <ion-icon name="create-outline" color="primary" slot="start"></ion-icon> <ion-text color="primary">{{'WRITE_REVIEW' | translate}}</ion-text>
  </ion-button>
</div>

<ion-modal mode="ios" trigger="open-modal" [initialBreakpoint]="0.7" [showBackdrop]="true">
  <ng-template>
    <ion-header mode="ios">
      <hb-header title="REVIEW">
        <ion-buttons slot="start">
          <ion-button (click)="cancel()">{{'CLOSE' | translate}}</ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
            @if ((formIsPending$ | async) === false) {
              <ion-button (click)="send()" [strong]="true" [disabled]="form.invalid">{{'SEND' | translate}}</ion-button>
            } @else {
              <ion-spinner name="dots" class="m-r-10" color="primary"></ion-spinner>
            }
        </ion-buttons>
      </hb-header>
    </ion-header>

    <ion-content class="ion-padding">
      <form [formConnect]="formName" [formGroup]="form" class="login-form" (onSuccessForm)="onSuccessForm()">
        <div class="ion-margin-vertical">
          <div class="flex ion-justify-content-center m-b-20">
            @for (it of reviews; track it; let index = $index; let last = $last) {
              <ion-icon
                class="hb-stars"
                [name]="it.icon"
                [ngClass]="{'ion-margin-end': !last}"
                (click)="onClick(index)"
              ></ion-icon>
            }
          </div>

          <ion-textarea
            [autoGrow]="true"
            mode="md"
            [label]="'SHARE_REVIEW_DETAILS' | translate"
            labelPlacement="floating"
            fill="outline"
            [placeholder]="'ENTER_TEXT' | translate"
            formControlName="message"
          ></ion-textarea>
        </div>
      </form>
    </ion-content>
  </ng-template>
</ion-modal>
