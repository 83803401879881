@if (item?.message) {
  <div>
    @if (item.user_info.id !== myId) {
      <div class="flex ion-align-items-end ion-margin-start">
        <hb-avatar class="m-r-6" [data]="item.user_info" size="small" [routerLink]="'/card/'+item.user_info.id+'/master'"></hb-avatar>
        <div class="flex">
          <div class="message-box left-radius m-b-10 ion-align-items-end">
            <ion-label class="m-b-6 color-white sr-block">
              @for (line of item.message.split('\n'); track line) {
                @for (part of formatMessage(line); track part) {
                  @if (part.type === 'link') {
                    <div (click)="navigate(part.url, part.queryParams, $event)">
                      <ion-text color="primary">{{ part.text }}</ion-text>
                    </div>
                  } @else {
                    <div>{{ part.content }}</div>
                  }
                }
              }
            </ion-label>
            <div class="flex ion-justify-content-end word-break-keep-all w100">
              <ion-text class="font-size-10 m-l-6 color-white">{{item.created_at | localizedDate:'medium'}}</ion-text>
            </div>
          </div>

          <ion-buttons mode="ios" class="ion-margin-end">
            <ion-button (click)="onDelete(item.id)">
              <ion-icon color="medium" slot="icon-only" size="small" name="ellipsis-horizontal"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>
      </div>
    } @else {
      <div class="flex ion-align-items-end ion-margin-end ion-justify-content-end">
        <div class="flex">
          <ion-buttons mode="ios" class="ion-margin-start">
            <ion-button (click)="onDelete(item.id)">
              <ion-icon color="medium" slot="icon-only" size="small" name="ellipsis-horizontal"></ion-icon>
            </ion-button>
          </ion-buttons>

          <div class="message-box primary right-radius m-b-10 ion-align-items-end">
            <ion-label class="m-b-6 color-white sr-block">{{item.message}}</ion-label>
            <div class="flex ion-justify-content-end word-break-keep-all">
              <ion-text class="font-size-10 m-l-6 color-white">{{item.created_at | localizedDate:'medium'}}</ion-text>
              <ion-icon class="m-l-4" name="checkmark-done-outline" [color]="item.is_read ? 'secondary' : 'light'"></ion-icon>
            </div>
          </div>
        </div>
        <hb-avatar class="m-l-6" [data]="item.user_info" size="small" [routerLink]="'/card/'+item.user_info.id+'/master'"></hb-avatar>
      </div>
    }
  </div>
}
