import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { mapUserDTO } from '../utils/auth/auth.utils';
import { LoginDTO, UserDTO } from './auth.dto';
import { LoginData, Principal } from './auth.types';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FcmService, FirebaseAuthService, IAppState, MessagingService } from '../';
import { ClearAllForms, ClearAllPages, ClearPage, LoadPage, getUser } from '../store';
import { isEmptyValue, StoreServiceUtil } from '../services/utils';

export interface AuthServiceConfig {
    baseUrl: string;
    storageTokenKey: string;
    storagePrincipalKey: string;
}

export const AUTH_SERVICE_CONFIG = new InjectionToken<AuthServiceConfig>('AUTH_SERVICE_CONFIG');

@Injectable()
export class AuthService {
    constructor(
        @Inject(AUTH_SERVICE_CONFIG) private readonly config: AuthServiceConfig,
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly store: Store<IAppState>,
        private readonly storeUtils: StoreServiceUtil,
        private readonly messagingService: MessagingService,
        private readonly firebaseAuthService: FirebaseAuthService,
        public readonly fcmService: FcmService,
    ) {}

    private getUser(userId: number, token: string): Observable<any> {
        return this.http.get<UserDTO>(`/user/${userId}`, { headers: { Authorization: `Bearer ${token}` } }).pipe(map(mapUserDTO(userId)));
    }

    public login(data: LoginData): Observable<LoginDTO> {
        // logout before login, in order to reset stored auth params
        const { method, password } = data;

        return this.http.post<any>('/login', { method, password }).pipe(
            flatMap((dto: LoginDTO) => {
                void this.loadAndSetUserData(dto);
                return of(dto);
            }),
        );
    }

    public loadAndSetUserData(dto): void {
        localStorage.setItem(this.config.storageTokenKey, dto.token);
        this.setPrincipal(dto);
        this.store.dispatch(LoadPage({ path: getUser, reqData: { id: dto.id } }));
    }

    public setPrincipal(dto): void {
        localStorage.setItem(this.config.storagePrincipalKey, JSON.stringify(dto));
    }

    public get principal(): Principal | null {
        const principalStr: Principal =
            !isEmptyValue(localStorage.getItem(this.config.storagePrincipalKey)) &&
            JSON.parse(localStorage.getItem(this.config.storagePrincipalKey));
        if (principalStr) {
            return principalStr;
        } else {
            return null;
        }
    }

    public signUp(data: {
        user_name: string;
        first_name: string;
        last_name: string;
        code: string;
        phone: string;
        password: string;
        role: number;
    }): Observable<void> {
        const body = {
            user_name: data.user_name,
            first_name: data.first_name,
            last_name: data.last_name,
            code: data.code,
            phone: data.code + data.phone,
            password: data.password,
            role: data.role,
        };

        return this.http.post<void>('/sign-up', body);
    }

    public async logout(): Promise<void> {
        try {
            try {
                await this.fcmService.deleteFcm();
                await this.messagingService.deleteToken();
            } catch (e) {
                console.log(e);
            }

            this.removeLoginLocalStorageData();
            this.storeUtils.dispatchMultipleActions([ClearAllForms(), ClearAllPages(), ClearPage({ path: getUser })]);

            try {
                await this.firebaseAuthService.SignOut();
            } catch (e) {
                console.log(e);
            }
        } catch (e) {
            console.log(e);
        }
    }

    public removeLoginLocalStorageData(): void {
        localStorage.removeItem(getUser);
        localStorage.removeItem(this.config.storageTokenKey);
        localStorage.removeItem(this.config.storagePrincipalKey);
        void this.router.navigate(['/login']);
    }

    public get token(): string | undefined {
        return localStorage.getItem(this.config.storageTokenKey);
    }

    public get decodedToken(): string | undefined {
        return this.token && jwtDecode(this.token);
    }

    public get expiredAt(): number | undefined {
        return this.decodedToken ? +this.decodedToken['exp'] : undefined;
    }
}
