import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormEffects, SendForm, signUpFormName } from '../../store';
import { PhoneCodesService } from '@solar/core/src/services/rest/phone-codes';
import { SmsVerifyComponent } from '@solar/shared/components/statefull/sms-verify/sms-verify.component';
import { tap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { StoreServiceUtil } from '../../services/utils';
import { IAppState } from '@solar/core/src';
import { AuthService } from '@solar/core/src/auth/auth.service';

export const verifyPhoneFormName = 'verifyPhoneFormName';
export const verifySmsCode = 'verifySmsCode';
export const sendSmsCode = 'sendSmsCode';

@Injectable()
export class SmsCodesEffects {
    constructor(
        private readonly phoneCodesService: PhoneCodesService,
        private readonly store: Store<IAppState>,
        private readonly formEffects: FormEffects,
        private readonly modalCtrl: ModalController,
        private readonly storeUtils: StoreServiceUtil,
        private readonly authService: AuthService,
    ) {}

    public readonly $sendSmsCode = this.formEffects.getSendFormEffect(sendSmsCode, formState =>
        this.phoneCodesService.sendSmsCode(formState.formData),
    );

    public readonly $sendCode = this.formEffects.getSendFormEffect(verifyPhoneFormName, formState =>
        this.phoneCodesService.verifyPhone(formState.formData).pipe(
            tap(() => {
                void this.showModal(formState.formData.phone);
            }),
        ),
    );

    public readonly $verifySms = this.formEffects.getSendFormEffect(verifySmsCode, formState => {
        return this.phoneCodesService.verifyCode(formState.formData);
    });

    public async showModal(phone): Promise<void> {
        const modal = await this.modalCtrl.create({
            mode: 'ios',
            component: SmsVerifyComponent,
            presentingElement: document.querySelector('.ion-page'),
            componentProps: {
                phone,
            },
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        if (data) {
            const formData = this.storeUtils.getStateByPath(`core.forms.${signUpFormName}.formState.formData`);

            this.store.dispatch(SendForm({ formName: signUpFormName, formState: { formData } }));
        }
    }
}
