import { Injectable } from '@angular/core';
import { AlertController, AlertInput } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

interface IAlertServiceOptions {
    header?: string;
    subHeader?: string;
    message?: string;
    acceptText?: string;
    cancelText?: string;
    cssClass?: string;
    buttons?: {
        text: string;
        role?: string;
        cssClass?: string;
        handler?: () => void;
    }[];
    backdropDismiss?: boolean;
    inputs?: AlertInput[];
    mode?: 'md' | 'ios';
}

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    constructor(private readonly alertController: AlertController, private readonly translate: TranslateService) {}

    public async presentAlert(options: IAlertServiceOptions, cb?: (pr: any) => void): Promise<void> {
        const { header, subHeader, message, acceptText, cancelText, buttons, backdropDismiss, inputs, mode, cssClass } = options;

        const defaultButtons = [
            {
                text: this.translate.instant(cancelText ? cancelText : 'CANCEL') as string,
                role: 'cancel',
                cssClass: 'text-gray sr-button sr-no-border medium w100 text-center margin-b-8',
                handler: () => cb(false),
            },
            {
                text: this.translate.instant(acceptText ? acceptText : 'OK') as string,
                cssClass: 'text-light sr-button sr-no-border medium w100 bg-secondary',
                handler: (res: any) => {
                    if (res) {
                        return cb(res);
                    }

                    return cb(true);
                },
            },
        ];

        const alert = await this.alertController.create({
            header: header ? (this.translate.instant(header) as string) : '',
            subHeader: subHeader ? (this.translate.instant(subHeader) as string) : '',
            message: message ? (this.translate.instant(message) as string) : '',
            buttons: buttons || defaultButtons,
            backdropDismiss,
            inputs: inputs || [],
            mode: mode ? mode : 'ios',
            cssClass: cssClass ? `alert-wrapper ${cssClass}` : 'alert-wrapper',
        });

        await alert.present();
    }
}
