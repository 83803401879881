import { Routes } from '@angular/router';
import { UnauthorizedGuard } from '@solar/core/src/guards/unauthorized.guard';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./tabs/tabs.routing').then(m => m.routes),
    },
    {
        path: 'card/:id/:model',
        loadComponent: () => import('./pages/tabs-home/components/card/card.component').then(m => m.CardComponent),
    },
    {
        path: 'order-card',
        loadChildren: () => import('./pages/master-order-card/master-order-card.routing').then(m => m.routes),
    },
    {
        path: 'all-categories/:model',
        loadComponent: () => import('./pages/all-categories/all-categories.page').then(m => m.AllCategoriesPage),
    },
    {
        path: 'all-masters',
        loadChildren: () => import('./pages/all-masters/all-masters.routing').then(m => m.routes),
    },
    {
        path: 'login',
        loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage),
        canActivate: [UnauthorizedGuard],
    },
    {
        path: 'sign-up',
        loadComponent: () => import('./pages/sign-up/sign-up.page').then(m => m.SignUpPage),
        canActivate: [UnauthorizedGuard],
    },
    {
        path: 'password-reset',
        loadChildren: () => import('./pages/password-reset/password-reset.routing').then(m => m.routes),
    },
    {
        path: 'direct-messages',
        loadChildren: () => import('./pages/direct-messages/direct-messages.routing').then(m => m.routes),
    },
    {
        path: 'location/:id',
        loadChildren: () => import('./pages/google-maps/google-maps.routing').then(m => m.routes),
    },
    {
        path: 'privacy-policy',
        loadComponent: () => import('./privacy-policy/privacy-policy.page').then(m => m.PrivacyPolicyPage),
    },
    {
        path: 'statistic',
        loadComponent: () => import('./pages/statistic/statistic.component').then(m => m.StatisticComponent),
    },
    {
        path: 'server-error',
        loadComponent: () =>
            import('../../libs/shared/src/components/stateless/server-error/server-error.component').then(m => m.ServerErrorComponent),
    },
    {
        path: '**',
        loadComponent: () =>
            import('../../libs/shared/src/components/stateless/not-found/not-found.component').then(m => m.NotFoundComponent),
        pathMatch: 'full',
    },
];
