import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { loadBalances, updateBalance } from './selectors';
import { PagesEffects } from '../pages';
import { FormEffects } from '../forms';
import { BalancesService } from '../../services';
import { IAppState } from '../../interfaces';

@Injectable()
export class BalancesEffects {
    public readonly loadAll$ = this.pagesEffects.getLoadPageEffect(loadBalances, () => this.descriptionsService.loadAll());
    public readonly updateBalance$ = this.formEffects.getSendFormEffect(updateBalance, formState =>
        this.descriptionsService.update(formState.formData)
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<IAppState>,
        private readonly pagesEffects: PagesEffects,
        private readonly formEffects: FormEffects,
        private readonly descriptionsService: BalancesService
    ) {}
}
