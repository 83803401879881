import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeoLocationData } from './geolocation.model';
import { Geolocation } from '@capacitor/geolocation';
import { lastValueFrom } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { IAddresses, timeoutUtil } from '@solar/core/src';

export interface GeocodingResult {
    results: any;
    address_components: {
        long_name: string;
        short_name: string;
        types: string[];
    }[];
}

@Injectable({
    providedIn: 'root',
})
export class GeoLocationService {
    private readonly apiUrl = 'https://ipinfo.io/json?token=302000f4593126';

    constructor(
        private readonly http: HttpClient,
        private readonly alertCtrl: AlertController,
    ) {}

    public getGeoLocationData(): Observable<GeoLocationData> {
        return this.http.get<GeoLocationData>(this.apiUrl);
    }

    public async getCurrentPosition(timer = 5000): Promise<IAddresses> {
        try {
            await timeoutUtil(timer);
            const coordinates = await Geolocation.getCurrentPosition();
            const { latitude, longitude } = coordinates.coords;

            return await this.getAddressFromCoordinatesAsync(latitude, longitude);
        } catch (error) {
            if (error.message.includes('User denied Geolocation')) {
                const alert = await this.alertCtrl.create({
                    header: 'Location access was denied. Please in the setting to enable location services and try again.',
                });

                void alert.present();
            }
        }
    }

    public getAddressFromCoordinates(lat, lon): Observable<IAddresses> {
        return this.http.get<IAddresses>(`/geolocation/${lat},${lon}`);
    }

    public async getAddressFromCoordinatesAsync(lat, lon): Promise<IAddresses> {
        return await lastValueFrom(this.getAddressFromCoordinates(lat, lon));
    }
}
