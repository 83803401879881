import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICollectionResponse, IReviews } from '../../../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ReviewsService {
    constructor(private readonly http: HttpClient) {}

    public addReview(body: IReviews): Observable<ICollectionResponse<any>> {
        return this.http.post<ICollectionResponse<any>>(`reviews`, body);
    }

    public loadReviews(req: { user_id: number }): Observable<ICollectionResponse<IReviews>> {
        return this.http.get<ICollectionResponse<IReviews>>(`reviews/${req.user_id}`);
    }
}
