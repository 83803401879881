import { Injectable } from '@angular/core';
import { FormEffects, PagesEffects } from '../../store';
import { WorkingHoursService } from '../../services';

export const getWorkingHours = 'getWorkingHours';
export const patchWorkingHours = 'patchWorkingHours';

export const getWorkingHoursUsers = 'getWorkingHoursUsers';

@Injectable()
export class WorkingHoursEffects {
    constructor(
        private readonly formEffects: FormEffects,
        private readonly pagesEffects: PagesEffects,
        private readonly workingHoursService: WorkingHoursService
    ) {}

    public readonly loadWorkingHours$ = this.pagesEffects.getLoadPageEffect(getWorkingHours, ({ reqData }) =>
        this.workingHoursService.getWorkingHours(reqData)
    );

    public readonly setWorkingHours$ = this.formEffects.getSendFormEffect(patchWorkingHours, ({ formData }) =>
        this.workingHoursService.patchWorkingHours(formData)
    );
    //
    public readonly loadUsersWorkingHours$ = this.pagesEffects.getLoadPageEffect(getWorkingHoursUsers, ({ reqData }) =>
        this.workingHoursService.getWorkingHoursUsers(reqData)
    );
}
