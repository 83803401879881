import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IMaster } from '../../../../libs/core/src/interfaces/masters';
import { ICollectionResponse } from '../../../../libs/core/src';

@Injectable({
    providedIn: 'root',
})
export class MasterServicesService {
    constructor(private readonly http: HttpClient) {}

    public async addMasterService(body): Promise<Object> {
        try {
            return await lastValueFrom(this.http.post(`${environment.api}/master-services`, body));
        } catch (e) {
            console.log(e);
        }
    }

    public async deleteMasterService(id: number): Promise<Object> {
        try {
            return await lastValueFrom(this.http.delete(`master-services/${id}`));
        } catch (e) {
            console.log(e);
        }
    }

    public async getAllMasterServices(master_id: number): Promise<ICollectionResponse<IMaster>> {
        try {
            return await lastValueFrom(this.http.get<ICollectionResponse<IMaster>>(`master-services/${master_id}`));
        } catch (e) {
            console.log(e);
        }
    }
}
