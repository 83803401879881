import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '../../auth/auth.service';
import { take } from 'rxjs/operators';
import { getUser, LoadPage, selectPageByName } from '../../store';
import { IAppState } from '../../interfaces';
import { OnlineWsService } from '@solar/core/src';
import { GeoLocationService } from '@solar/core/src/services/rest/geolocation';

@Injectable({
    providedIn: 'root',
})
export class AppInitService {
    public readonly authService = this.injector.get(AuthService);

    constructor(
        private readonly store: Store<IAppState>,
        private readonly injector: Injector,
        private readonly onlineWsService: OnlineWsService,
        private readonly geolocation: GeoLocationService,
    ) {}

    public appInit(): Promise<any> {
        this.onlineWsService.connectUser();

        if (!this.authService?.principal) {
            return Promise.resolve();
        }

        return new Promise<void>(resolve => {
            this.store
                .select(selectPageByName(getUser))
                .pipe(take(1))
                .subscribe(user => {
                    if (!user) {
                        if (this.authService?.principal?.id) {
                            this.store.dispatch(LoadPage({ path: getUser, reqData: { id: this.authService.principal.id } }));
                            void this.geolocation.getCurrentPosition().then();
                        }

                        resolve();
                    }
                });
        });
    }
}
