import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAppState, ILoadDataState } from '@solar/core/src';
import { Store } from '@ngrx/store';
import { categoriesPageName, CategoriesRes } from '@solar/core/src/store/categories';
import { LoadPage, selectPageByName } from '@solar/core/src/store';
import { StoreServiceUtil } from '@solar/core/src/services/utils';
import { CategoryServicesRes } from '@solar/core/src/store/category-services';
import { ServerTranslatePipe } from '../../../pipes/server-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'hb-dynamic-segment-services',
    templateUrl: './dynamic-segment-services.component.html',
    styleUrls: ['./dynamic-segment-services.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IonicModule, AsyncPipe, TranslateModule, ServerTranslatePipe],
})
export class DynamicSegmentServicesComponent {
    @Input() public data: Observable<ILoadDataState<CategoryServicesRes[]>> = this.store.select(selectPageByName(categoriesPageName));
    @Input() public categories: Observable<ILoadDataState<CategoriesRes[]>> = this.store.select(selectPageByName(categoriesPageName));

    @Input() public filterIdName: string = 'category_id';

    @Output() public selected = new EventEmitter();

    constructor(private readonly store: Store<IAppState>, private readonly storeUtils: StoreServiceUtil) {
        this.storeUtils.dispatchIfEmpty(`core.pages.${categoriesPageName}.pathState.data`, LoadPage({ path: categoriesPageName }));
    }

    public get typesTabsFilters$(): Observable<any> {
        return combineLatest([this.data, this.categories]).pipe(
            map(([dataState, categoriesState]) => {
                if (dataState?.data && categoriesState?.data) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    const uniqueIds = new Set(dataState.data.map(item => item[this.filterIdName]));
                    const filteredCategories = categoriesState.data.filter(category => uniqueIds.has(category.id));
                    return filteredCategories;
                }
                return [];
            })
        );
    }

    public onSelect(ev): void {
        void this.selected.emit(ev);
    }
}
