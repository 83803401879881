import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UserRes } from '@solar/core/src/store';
import { DateAgoPipe } from '@solar/shared';
import { OnlineWsService } from '@solar/core/src';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { LowerCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass as NgClassDirective } from '@angular/common';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'hb-online-last-seen',
    templateUrl: './online-last-seen.component.html',
    styleUrls: ['./online-last-seen.component.scss'],
    standalone: true,
    imports: [CommonModule, IonicModule, DateAgoPipe, TranslateModule, LowerCasePipe, NgClassDirective],
})
export class OnlineLastSeenComponent {
    @Input() public user: UserRes;
    @Input() public ngClass: string = 'font-size-12';
    @Input() public color: string = 'medium';

    constructor(
        protected readonly onlineWsService: OnlineWsService,
        protected readonly authService: AuthService,
    ) {}
}
