import { Injectable, signal } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { take } from 'rxjs/operators';
import { loadMessages, LoadPageSuccess, selectPageByName } from '../../store';
import { Store } from '@ngrx/store';
import { IAppState } from '../../interfaces';
import { IMessage } from '../../services';
import { AuthService } from '../../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class MessagesWsService {
    public readonly sendMessageSignal = signal<any>(null);
    public readonly typingMessageSignal = signal<any>(null);

    constructor(
        private readonly socket: Socket,
        private readonly store: Store<IAppState>,
        private readonly authService: AuthService,
    ) {
        this.listenSendMessage();
        this.listenDeleteMessage();
        this.listenTyping();
    }

    public connectUser(): void {
        this.socket.emit('user_connected', this.authService?.principal?.id);
    }

    public listenSendMessage(): void {
        this.socket.on('message', action => {
            this.store
                .select(selectPageByName(loadMessages)) // Замените на ваши селекторы и константы
                .pipe(take(1))
                .subscribe(res => {
                    this.store.dispatch(LoadPageSuccess({ path: loadMessages, data: { data: [...res.data, action.payload] } }));
                    this.sendMessageSignal.set(res);
                });
        });
    }

    public listenTyping(): void {
        this.socket.on('typing', res => {
            this.typingMessageSignal.set(res);
        });
    }

    public listenDeleteMessage(): void {
        this.socket.on('delete_message', action => {
            this.store
                .select(selectPageByName(loadMessages))
                .pipe(take(1))
                .subscribe(res => {
                    const data = res.data.filter(it => it.id !== action.payload);

                    this.store.dispatch(LoadPageSuccess({ path: loadMessages, data: { data } }));
                });
        });
    }

    public send(message: any, recipientUserId): void {
        this.socket.emit('send_message', message, recipientUserId);
    }

    public addTyping(recipientUserId: number, user): void {
        this.socket.emit('add_typing_messages', recipientUserId, user);
    }

    public setMessagesRead(messages: IMessage[]): void {
        this.socket.emit('messages_read', messages);
    }

    public offline(): void {
        this.socket.off('send_message');
        this.socket.off('delete_message');
    }
}
