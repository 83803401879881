import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AlertService } from '../alert';
import { CapacitorHttp } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { IEnv, ENV } from '../../';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

export const openSite = async (url: string): Promise<void> => {
    await Browser.open({ url });
};

@Injectable({
    providedIn: 'root',
})
export class UpdateForceService {
    public versionsData?: { android: string; ios: string; force: boolean };

    constructor(
        private readonly plt: Platform,
        private readonly alertService: AlertService,
        private readonly translate: TranslateService,
        @Inject(ENV) private readonly env: IEnv
    ) {}

    public checkForUpdate(): void {
        if (this.env?.production) {
            const options = {
                url: 'https://hey-beauty.app/uploads/files/appVersions.json',
            };

            void CapacitorHttp.get(options).then(async res => {
                if (res && res.data) {
                    this.versionsData = res.data;

                    await this.checkConditions(this.plt.is('ios') ? this.versionsData?.ios : this.versionsData?.android);
                }
            });
        }
    }

    public async checkConditions(newVersion: string | undefined): Promise<void> {
        if (newVersion) {
            const appVersion = (await App.getInfo()).version;

            const splittedCurrentAppVersion = appVersion.split('.');

            const splittedNewVersion = newVersion.split('.');

            if (splittedCurrentAppVersion[0] < splittedNewVersion[0]) {
                await this.showConfirm();
                return;
            } else if (splittedCurrentAppVersion[0] === splittedNewVersion[0] && splittedCurrentAppVersion[1] < splittedNewVersion[1]) {
                await this.showConfirm();
                return;
            } else if (
                splittedCurrentAppVersion[0] === splittedNewVersion[0] &&
                splittedCurrentAppVersion[1] === splittedNewVersion[1] &&
                splittedCurrentAppVersion[2] < splittedNewVersion[2]
            ) {
                await this.showConfirm();
                return;
            }
        }
    }

    public async showConfirm(): Promise<void> {
        const defaultConfigs = {
            header: 'NEW_VERSION_TITLE',
            acceptText: 'UPDATE',
        };

        const forceConfigs = {
            header: 'FORCE_NEW_VERSION_TITLE',
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('UPDATE') as string,
                    handler: () => this.openAppStoreEntry(),
                },
            ],
        };

        await this.alertService.presentAlert(this.versionsData?.force ? forceConfigs : defaultConfigs, cb =>
            cb ? this.openAppStoreEntry() : ''
        );
    }

    public openAppStoreEntry(): void {
        if (this.plt.is('android')) {
            void openSite('https://play.google.com/store/apps/details?id=com.solar.limited&itscg=30200').then(() => {
                void Browser.close();
            });
        } else if (this.plt.is('ios')) {
            void openSite('https://apps.apple.com/ua/app/hey-beauty-master/id6467883609').then(() => {
                void Browser.close();
            });
        }
    }
}
