<div [formGroup]="form">
  <ion-select
    [ngClass]="{'ion-margin-bottom': mode === 'md'}"
    [mode]="mode"
    label="{{ 'SERVICES' | translate }}"
    label-placement="fixed"
    fill="outline"
    [formControlName]="controlName"
    [multiple]="multiple"
    [compareWith]="compareWith"
    (ionChange)="onChange($event)"
    >
    @for (service of (services$ | async)?.data; track service) {
      <ion-select-option
      [value]="service.id">{{(service | serverTranslate) | titlecase}}</ion-select-option>
    }
  </ion-select>
</div>
