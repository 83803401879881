export function normalizeTime(time): string {
    const [hours, minutes] = time.split(':');
    // Add leading zeros to hours and minutes and return in HH:MM format
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
}

export function normalizeTimes(times: string): string {
    return times
        .split(',') // Разбиваем строку на массив временных меток
        .map(normalizeTime) // Применяем функцию normalizeTime к каждому элементу
        .join(','); // Объединяем обратно в строку
}
