@if (onlineWsService.isOnline(user.id)) {
  <ng-container *ngTemplateOutlet="statusTemplate; context: { statusText: ('ONLINE' | translate), color: 'primary' }"></ng-container>
} @else if (user && user?.last_seen && this.authService?.principal?.id !== user?.id) {
  <ng-container
    *ngTemplateOutlet="statusTemplate; context: { statusText: ('LAST_SEEN' | translate) + ' ' + (user?.last_seen | dateAgo | lowercase), color: color }"
  ></ng-container>
}

<ng-template #statusTemplate let-statusText="statusText" let-color="color">
  <div class="font-weight-400 ion-text-lowercase">
    <ng-content></ng-content>
    <ion-text [color]="color" [ngClass]="ngClass">{{ statusText }}</ion-text>
  </div>
</ng-template>
