import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { CategoriesRes } from '../../../store/categories';
import { ICollectionResponse } from '../../../interfaces';

@Injectable({
    providedIn: 'root',
})
export class CategoriesService {
    constructor(private readonly http: HttpClient) {}

    public addCategory(body: CategoriesRes): Observable<[]> {
        return this.http.post<[]>('/categories', this.formData(body));
    }

    public editCategory(body: CategoriesRes): Observable<[]> {
        return this.http.patch<[]>(`categories-full-edit/${body.id}`, this.formData(body));
    }

    public async deleteCategory(id: number): Promise<Object> {
        try {
            return await lastValueFrom(this.http.delete(`categories/${id}`));
        } catch (e) {
            console.log(e);
        }
    }

    public async patchCategory(id: number, body: { is_active: boolean }): Promise<Object> {
        try {
            return await lastValueFrom(this.http.patch(`categories/${id}`, body));
        } catch (e) {
            console.log(e);
        }
    }

    // public async getAllCategories(): Promise<CategoriesRes[]> {
    //     try {
    //         return await lastValueFrom(this.http.get(`categories`, { responseType: 'json' }));
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    public loadCategories(): Observable<ICollectionResponse<CategoriesRes>> {
        return this.http.get<ICollectionResponse<CategoriesRes>>(`categories`);
    }

    public loaCategory(id: number): Observable<ICollectionResponse<CategoriesRes>> {
        return this.http.get<ICollectionResponse<CategoriesRes>>(`categories/${id}`);
    }

    protected formData(body: CategoriesRes): FormData {
        const formData = new FormData();
        formData.append('img', body.img);
        formData.append('title', body.title);
        formData.append('title_ru', body.title_ru);
        formData.append('type', body.title);
        formData.append('position', `${body.position}`);
        formData.append('is_active', `${body.is_active}`);
        formData.append('model', body.model);
        formData.append('manager_id', `${body.manager_id}`);
        formData.append('managers', JSON.stringify(body['managers']));

        return formData;
    }
}
