import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { IAppState, ToastService } from '@solar/core/src';
import { SendForm, sendSmsCode, verifySmsCode } from '@solar/core/src/store';
import { TranslateModule } from '@ngx-translate/core';
import { SubmitComponent } from '../../../form-components';
import { HeaderComponent } from '../../stateless';
import { FormConnectDirective, InputErrorDirective } from '../../../directives';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'hb-sms-verify',
    templateUrl: './sms-verify.component.html',
    styleUrls: ['./sms-verify.component.scss'],
    standalone: true,
    imports: [
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        SubmitComponent,
        FormConnectDirective,
        HeaderComponent,
        InputErrorDirective,
        AsyncPipe,
        DatePipe,
    ],
})
export class SmsVerifyComponent implements OnInit {
    public form: FormGroup;
    public readonly formName = verifySmsCode;

    @Input() public phone: number;
    @Input() public description: string;

    public timer$?: Observable<number>;

    constructor(
        private readonly fb: FormBuilder,
        public readonly modalCtrl: ModalController,
        private readonly toastService: ToastService,
        private readonly store: Store<IAppState>,
    ) {}

    public ngOnInit(): void {
        this.form = this.fb.group({
            phone: ['', Validators.required],
            code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
        });

        this.onSendAgain();
    }

    public ionViewWillEnter(): void {
        if (this.phone) {
            this.form.get('phone').setValue(this.phone);
        }
    }

    public onSuccess(ev): void {
        void this.modalCtrl.dismiss(ev);

        void this.toastService.presentToast('CODE_SUCCESSFULLY_VERIFIED');

        this.form.get('code').setValue('');
    }

    public onSendAgain(): void {
        this.startTimer();

        const formData = {
            phone: this.phone,
        };

        this.store.dispatch(SendForm({ formName: sendSmsCode, formState: { formData } }));
    }

    public startTimer(): void {
        const period = 180;

        this.timer$ = timer(0, 1000).pipe(
            map(i => period - i),
            take(period + 1),
        );
    }
}
