import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpParams } from '@angular/common/http';
import { Inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export type HttpParamsFactory = () => HttpParams;

export const HTTP_PARAMS_CONFIG = new InjectionToken<any>('HTTP_PARAMS_CONFIG');

export class HttpParamsInterceptor implements HttpInterceptor {
    constructor(@Inject(HTTP_PARAMS_CONFIG) private readonly httpParamsFactory: HttpParamsFactory) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const origHttpParams = request.params;

        let httpParams = this.httpParamsFactory();

        origHttpParams.keys().forEach(key => {
            httpParams = httpParams.append(key, origHttpParams.get(key));
        });

        request = request.clone({
            params: httpParams,
        });

        return next.handle(request);
    }
}
