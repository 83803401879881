import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Clipboard } from '@capacitor/clipboard';
import { ToastController, IonicModule } from '@ionic/angular';
import { OrderStatusesEnum } from '@solar/enums/order-statuses.enum';
import { ServerTranslatePipe } from '../../../../pipes/server-translate.pipe';
import { LocalizedCurrencyPipe } from '../../../../pipes/localized-currency.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from '../../../stateless/avatar/avatar.component';
import { NgClass, DatePipe } from '@angular/common';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';

@Component({
    selector: 'hb-admin-orders-list-item',
    templateUrl: './admin-orders-list-item.component.html',
    styleUrls: ['./admin-orders-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IonicModule, NgClass, AvatarComponent, DatePipe, TranslateModule, LocalizedCurrencyPipe, ServerTranslatePipe],
})
export class AdminOrdersListItemComponent {
    @Input() public statuses = OrderStatusesEnum;

    @Input() public it: IOrders;

    @Output() public search = new EventEmitter<string>();
    @Output() public menuEvent = new EventEmitter<IOrders>();

    constructor(private readonly toastController: ToastController) {}

    public sumServicesTimes(hours): string | number {
        const arrHours: string = hours.split(',');

        if (arrHours.length <= 1) {
            return arrHours[0];
        }

        return arrHours[0] + '-' + arrHours[arrHours.length - 1];
    }

    public async writeToClipboard(string: string): Promise<void> {
        await Clipboard.write({
            string,
        });

        await this.presentToast(string + ' copied');
    }

    public async presentToast(message: string): Promise<void> {
        const toast = await this.toastController.create({
            message,
            duration: 1500,
            position: 'top',
            icon: 'document-text-outline',
        });

        await toast.present();
    }

    public onSearch(phone: string): void {
        this.search.emit(phone);
    }

    public statusColor = (order_status_id: OrderStatusesEnum): string => {
        switch (order_status_id) {
            case OrderStatusesEnum.CLOSE_DATE:
            case OrderStatusesEnum.PENDING_ACCEPTANCE:
                return 'warning';
            case OrderStatusesEnum.ACCEPTED:
            case OrderStatusesEnum.PAID:
                return 'success';
            default:
                'medium';
        }
    };

    protected readonly OrderStatusesEnum = OrderStatusesEnum;
}
