import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PushSubsService {
    constructor(private readonly http: HttpClient) {}

    public async addFCM(body: { fcm_token: string }): Promise<object> {
        try {
            return await lastValueFrom(this.http.post(`fcm-token`, body));
        } catch (e) {
            console.log(e);
        }
    }

    public async deleteFCM(fcm_token: string): Promise<object> {
        try {
            return await lastValueFrom(this.http.delete(`fcm-token/${fcm_token}`));
        } catch (e) {
            console.log(e);
        }
    }
}
