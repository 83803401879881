import { Injectable } from '@angular/core';
import { FormEffects, IFormStateGeneric, MasterInfo, SendForm } from '../../store';
import { MastersService } from '../../../../../src/app/core/services';
import { DateService, IAppState } from '@solar/core/src';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { SmsVerifyComponent } from '@solar/shared/components/statefull/sms-verify/sms-verify.component';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { ModalController } from '@ionic/angular';

export const addMasterFormName = 'addMasterFormName';
export const editMasterFormName = 'editMasterFormName';
export const deleteAvatar = 'deleteAvatar';

@Injectable()
export class MastersEffects {
    constructor(
        private readonly formEffects: FormEffects,
        private readonly mastersService: MastersService,
        private readonly dateService: DateService,
        private readonly store: Store<IAppState>,
        private readonly modalCtrl: ModalController,
        private readonly authService: AuthService,
    ) {}

    public readonly $addCategory = this.formEffects.getSendFormEffect(addMasterFormName, (formState: IFormStateGeneric<MasterInfo>) =>
        this.mastersService.add({
            ...formState.formData,
            birth_date: formState.formData?.birth_date ? this.dateService.getFormattedDateForServer(formState.formData?.birth_date) : null,
        }),
    );

    public readonly $editMaster = this.formEffects.getSendFormEffect(editMasterFormName, (formState: IFormStateGeneric<MasterInfo>) => {
        return this.mastersService
            .edit({
                ...formState.formData,
                birth_date: formState.formData?.birth_date
                    ? this.dateService.getFormattedDateForServer(formState.formData?.birth_date)
                    : null,
            })
            .pipe(
                tap(async res => {
                    if (res?.phoneNumberNeedVerify) {
                        await this.showModalForVerify({ phone: res.phone, description: 'VERIFY_YOUR_PHONE' });
                    }
                }),
            );
    });

    public readonly deleteAvatar$ = this.formEffects.getSendFormEffect(deleteAvatar, () => this.mastersService.deleteAvatar());

    public async showModalForVerify(options: { phone: string; description?: string }): Promise<void> {
        const modal = await this.modalCtrl.create({
            mode: 'ios',
            component: SmsVerifyComponent,
            presentingElement: document.querySelector('.ion-page'),
            componentProps: {
                phone: options.phone,
                description: options.description,
            },
        });

        await modal.present();

        const { data } = await modal.onDidDismiss();

        if (data?.is_used) {
            this.store.dispatch(
                SendForm({
                    formName: editMasterFormName,
                    formState: { formData: { id: this.authService?.principal?.id, phone_verify_date: data.created_at } },
                }),
            );
        }
    }
}
