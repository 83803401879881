import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { AddressesModel, IAddresses } from '@solar/core/src/services/rest/addresses/addresses.model';

@Injectable({
    providedIn: 'root',
})
export class AddressesService {
    constructor(private readonly http: HttpClient) {}

    public async getUserAddress(user_id: number): Promise<AddressesModel> {
        return await lastValueFrom(this.http.get<AddressesModel>(`/addresses/${user_id}`));
    }

    public addAddress(body: AddressesModel): Observable<AddressesModel> {
        return this.http.post<AddressesModel>(`/addresses`, body);
    }

    public editAddress(body: AddressesModel): Observable<AddressesModel> {
        return this.http.patch<AddressesModel>(`/addresses`, body);
    }

    public async deleteAddressAsync(id: number): Promise<IAddresses> {
        return await lastValueFrom(this.http.delete<IAddresses>(`/addresses/${id}`));
    }
}
