import { Injectable } from '@angular/core';
import { UserRolesEnum } from '../../enums';
import { AuthService } from '@solar/core/src/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserRoleService {
    constructor(private readonly authService: AuthService) {}

    public get isMaster(): boolean {
        if (!this.authService?.principal) {
            return false;
        }

        return this.authService?.principal?.role === UserRolesEnum.MASTER;
    }
    public get isAdminOrMaster(): boolean {
        if (!this.authService?.principal) {
            return false;
        }

        return this.isAdmin || this.isMaster;
    }

    public get isAdmin(): boolean {
        if (!this.authService?.principal) {
            return false;
        }

        return this.authService?.principal?.role === UserRolesEnum.ADMIN;
    }

    public get isClient(): boolean {
        if (!this.authService?.principal) {
            return false;
        }

        return this.authService?.principal?.role === UserRolesEnum.CLIENT;
    }
}
