import { Injectable } from '@angular/core';
import { FormEffects, PagesEffects } from '../index';
import { UserService } from '../../services/rest/user';
import { map } from 'rxjs/operators';
import { FcmService, IRestOptions, ToastService } from '../../index';
import { AuthService } from '../../auth/auth.service';

export const getUser = 'getUserPathName';
export const updateUserFormName = 'updateUserFormName';
export const deleteAccount = 'deleteAccountFormName';

export const loadMastersPathName = 'loadMastersPathName';
export const loadDashboardMasters = 'loadDashboardMasters';

export const loadUsersPathName = 'loadUsersPathName';

@Injectable()
export class UserEffects {
    constructor(
        private readonly formEffects: FormEffects,
        private readonly pagesEffects: PagesEffects,
        private readonly usersService: UserService,
        private readonly authService: AuthService,
        private readonly toastService: ToastService,
        private readonly fcmService: FcmService,
    ) {}

    public readonly loadUser$ = this.pagesEffects.getLoadPageEffect(getUser, ({ reqData }) =>
        this.usersService.getUser(reqData.id).pipe(
            map(res => {
                this.authService.setPrincipal(res);
                this.fcmService.initPush();

                return { data: res };
            }),
        ),
    );

    public readonly updateUser$ = this.formEffects.getSendFormEffect(updateUserFormName, formState =>
        this.usersService.updateUser(formState.formData),
    );

    public readonly deleteAccount$ = this.formEffects.getSendFormEffect(deleteAccount, () => {
        return this.usersService.deleteAccount().pipe(
            map(async () => {
                void this.authService.logout();
                await this.toastService.presentToast('ACCOUNT_SUCCESSFULLY_DELETED');
            }),
        );
    });

    public readonly loadMasters$ = this.pagesEffects.getLoadPageEffect(loadMastersPathName, ({ reqData }) =>
        this.usersService.getMasters(reqData),
    );

    public readonly loadDashboardMasters$ = this.pagesEffects.getLoadPageEffect(loadDashboardMasters, ({ reqData }) =>
        this.usersService.getMasters(reqData),
    );

    public readonly loadUsers$ = this.pagesEffects.getLoadPageEffect(loadUsersPathName, ({ reqData }: IRestOptions) =>
        this.usersService.getUsers(reqData),
    );

    public readonly loadUsersPush$ = this.pagesEffects.getLoadPagePushEffect(loadUsersPathName, ({ page, reqData }: IRestOptions) =>
        this.usersService.getUsers({ page, ...reqData }),
    );
}
