<ion-header>
  <hb-header title="{{ (service ? 'EDIT' : 'ADD') | translate }} {{model && ((model === CardModelsEnum.SHOP ? 'PRODUCT' : 'SERVICE') | translate)}}">
    <ion-button slot="end" (click)="onClose()" fill="clear" color="medium" mode="ios">
      <ion-icon slot="icon-only" name="close-outline"></ion-icon>
    </ion-button>
  </hb-header>
</ion-header>
<ion-content>
  <ion-list mode="ios" [inset]="true">
  <form [formConnect]="formName" [formGroup]="form" class="login-form" (onSuccessForm)="onSuccessForm($event)">
    <ion-item>
      <ion-input
        fill="outline"
        [label]="'TITLE' | translate"
        formControlName="title"
      ></ion-input>
    </ion-item>

    <ion-item>
    <ion-input
      fill="outline"
      formControlName="title_ru"
      >
      <div slot="label">
        {{'TITLE_IN_RU' | translate}}
        <ion-text color="medium" class="font-size-14"><sup> ({{'OPTIONAL' | translate}})</sup></ion-text>
      </div>
    </ion-input>
    </ion-item>

      <ion-item>
        <ion-input
          fill="outline"
          [label]="'PRICE' | translate"
          formControlName="price"
          placeholder="100"
          type="number"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          fill="outline"
          formControlName="price_to"
          type="number"
        >
          <div slot="label">{{'PRICE_TO' | translate}}<ion-text color="medium" class="font-size-14"><sup> ({{'OPTIONAL' | translate}})</sup></ion-text></div>
        </ion-input>
      </ion-item>

    <ion-item>
      <ion-select formControlName="currency" value="EUR" [label]="'CURRENCY' | translate" label-placement="fixed">
        <ion-select-option value="EUR">EUR</ion-select-option>
        <ion-select-option value="USD">USD</ion-select-option>
        <ion-select-option value="UAH">UAH</ion-select-option>
      </ion-select>
    </ion-item>
    <!--        // show only masters-->
    @if (category?.master || !form?.controls?.category_id?.value || !form?.controls?.type?.value) {
      <ion-item>
        <hb-services-select
          mode="ios"
          controlName="category_id"
          [form]="form"
          [multiple]="false"
          (onChangeEvent)="onTypeChange($event)"
        ></hb-services-select>
      </ion-item>
    }

    @defer (when model !== CardModelsEnum.SHOP) {
      <ion-item>
        <ion-select
          [label]="'SERVICE_TIME' | translate"
          fill="outline"
          formControlName="service_time"
        >
          <ion-select-option value="0.5">30 {{'MINUTES' | translate}}</ion-select-option>
          <ion-select-option value="1">{{'ONE_HOUR' | translate}}</ion-select-option>
          <ion-select-option value="1.5">1.5 {{'HOURS_FULL' | translate}}</ion-select-option>
          <ion-select-option value="2.0">{{'TWO_HOURS' | translate}}</ion-select-option>
          <ion-select-option value="2.5">2.5 {{'HOURS_FULL' | translate}}</ion-select-option>
          <ion-select-option value="3.0">{{'THREE_HOURS' | translate}}</ion-select-option>
          <ion-select-option value="3.5">3.5 {{'HOURS_FULL' | translate}}</ion-select-option>
          <ion-select-option value="4">{{'FOUR_HOURS' | translate}}</ion-select-option>
          <ion-select-option value="5">{{'FIVE_HOURS' | translate}}</ion-select-option>
        </ion-select>
      </ion-item>
    }

    @if(masters?.length) {
      <ion-item>
        <ion-select helperText="hello" formControlName="masters" [label]="'SELECT_MASTERS' | translate" [multiple]="true" fill="outline">
          @for (m of masters; track m.id) {
            <ion-select-option [value]="m.id">{{m.user_name }}</ion-select-option>
          }
        </ion-select>
      </ion-item>
      <div class="ion-margin-start">
        <ion-note mode="ios" color="medium" class="font-size-14">{{'MARK_BELOW_MASTERS' | translate}}</ion-note>
      </div>
    }

    @defer (when model === CardModelsEnum.SHOP) {
      <ion-item>
        <ion-input formControlName="stock_available" label="В наличии кол-во" type="number" placeholder="XXX"></ion-input>
      </ion-item>
    }

    <ion-item>
      <ion-textarea [autoGrow]="true" [label]="'DESCRIPTION' | translate" labelPlacement="floating" formControlName="description"></ion-textarea>
    </ion-item>

    <ion-item button class="file-upload-wrapper"  detail-icon="image-outline">
      <ion-label>{{imagePreviewUrl ? '': ('SELECT_IMAGE' | translate)}}</ion-label>
      <input (change)="onSelectFile($event)" name="file-upload-field" type="file" class="file-upload-field" value="" />
    </ion-item>

    <div class="ion-margin-horizontal">
      <img class="img-responsive" style="max-width: 480px;max-height: 480px; border-radius: 9px" [src]="imagePreviewUrl" alt="" />
    </div>

    <div class="ion-margin-horizontal ion-margin-bottom">
      <hb-submit [form]="form" [formName]="formName" [configs]="{label: (service ? 'EDIT' : 'ADD')}"></hb-submit>
    </div>
  </form>
  </ion-list>
</ion-content>
