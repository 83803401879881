<ng-container *showExceptMe="categoryId">
  <ng-container *isAuthorized>
    <ion-header mode="ios">
      <hb-header title="" backUrl="/direct-messages" backUrlText="">
          @if (chatMember) {
            <div class="flex ion-align-items-center" [routerLink]="'/card/'+(categoryId)+'/master'">
              <hb-avatar size="small" slot="start" [data]="chatMember"></hb-avatar>
              <div>
                <div class="m-l-10">
                  {{chatMember | fullName}} <br>
                </div>
                <div class="m-l-10">
                  <ion-text color="medium" class="font-size-12">
                    {{chatMember?.user_name}}
                  </ion-text>
                </div>
              </div>
            </div>
          }
      </hb-header>
    </ion-header>

    <ion-content #content  scrollEvents="true" (ionScroll)="contentScrolled($event)">
      <hb-chat-modal-list class="ion-margin-bottom sr-block" [content]="content" [categoryId]="categoryId"></hb-chat-modal-list>

      @if (scrollPercentage < 0.7 && scrollPercentage > 0.01) {
        <ion-fab vertical="bottom" horizontal="end" slot="fixed">
          <ion-fab-button color="light" size="small" (click)="scrollToBottomOnInit()">
            <ion-icon color="dark" name="chevron-down"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      }
    </ion-content>

    <ion-footer class="ion-no-border">
      <ion-toolbar>
        <div class="ion-padding-horizontal m-b-4" style="min-height: 19px">
          @if (typingSignal()) {
            @if (typingSignal()?.sender_info) {
              <ion-text class="font-size-12" *showExceptMe="typingSignal()?.sender_info.id">
                <i>
                  {{(typingSignal()?.sender_info?.user_name ? typingSignal()?.sender_info?.user_name : typingSignal()?.sender_info?.first_name) + ' '}} {{'typing' | translate}}
                </i>
              </ion-text>
            }
          }
        </div>

        <form (submit)="onSubmit()" [formConnect]="formName" [formGroup]="form" (onSuccessForm)="onSuccessForm($event)">
          <div class="ion-margin-horizontal ion-margin-bottom flex message-box">
            <ion-list [inset]="true" class="ion-no-margin margin-no w100 ion-no-padding">
              <ion-item>
                <ion-textarea
                  rows="1"
                  #textarea
                  [autoGrow]="true"
                  mode="md"
                  [placeholder]="'ENTER_TEXT' | translate"
                  formControlName="message"
                  class="message-box-textarea"
                  (ionFocus)="scrollToBottomOnInit()"
                  (ionBlur)="scrollToBottomOnInit()"
                ></ion-textarea>
              </ion-item>
            </ion-list>

              <ion-buttons slot="end">
                <ion-button size="large" color="pink" [disabled]="form.invalid" type="submit">
                  <ion-icon  slot="icon-only" name="send-outline"></ion-icon>
                </ion-button>
              </ion-buttons>
          </div>
        </form>
      </ion-toolbar>
    </ion-footer>
  </ng-container>
</ng-container>
