import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addSlidesFormName, slidePageName, slidesPageName } from './selectors';
import { PagesEffects } from '../pages';
import { FormEffects } from '../forms';
import { map } from 'rxjs/operators';
import { SlidesService } from '../../services';

@Injectable()
export class SlidesEffects {
    public readonly $addSlide = this.formEffects.getSendFormEffect(addSlidesFormName, formState => {
        return this.slidesService.addSlides(formState.formData);
    });

    public readonly loadSlides$ = this.pagesEffects.getLoadPageEffect(slidesPageName, ({ reqData }) =>
        this.slidesService.loadSlides(reqData.master_id).pipe(
            map(res => {
                return { data: res.data };
            })
        )
    );

    public readonly loadSlide$ = this.pagesEffects.getLoadPageEffect(slidePageName, ({ reqData }) => {
        return this.slidesService.loadSlide(reqData.id).pipe(
            map(res => {
                return { data: res };
            })
        );
    });

    // public readonly $editCategory = this.formEffects.getSendFormEffect(editSlideFormName, formState => {
    //     return this.slidesService.ed(formState.formData);
    // });

    // loadPushOffers$ = this.pagesEffects.getLoadPagePushEffect(offersPageName, ({ page, filters }) =>
    //     this.offersService.getAll({ page, filters }).pipe(map(res => ({ ...res, data: res.items })))
    // );
    //

    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<any>,
        private readonly pagesEffects: PagesEffects,
        private readonly formEffects: FormEffects,
        private readonly slidesService: SlidesService
    ) {}
}
