import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICollectionResponse } from '../../../interfaces';
import { BalancesRes } from '@solar/core/src/store';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class BalancesService {
    constructor(private readonly http: HttpClient) {}
    public loadAll(): Observable<ICollectionResponse<BalancesRes>> {
        return this.http.get<ICollectionResponse<BalancesRes>>(`balances`);
    }

    public update(body: BalancesRes): Observable<ICollectionResponse<BalancesRes>> {
        return this.http.patch<ICollectionResponse<BalancesRes>>(`balances`, body);
    }

    public async updateBalanceAsync(body: BalancesRes): Promise<ICollectionResponse<BalancesRes>> {
        try {
            return await lastValueFrom(this.http.patch(`balances`, body));
        } catch (e) {
            console.log(e);
        }
    }
}
