import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AsyncPipe, NgClass } from '@angular/common';
import { addReviewFormName, loadReviews } from '@solar/core/src/store/reviews';
import { IAppState } from '@solar/core/src';
import { Store } from '@ngrx/store';
import { LoadPage, SendForm } from '@solar/core/src/store';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from '../../../components/stateless/header/header.component';
import { FormConnectDirective } from '../../../directives';
import { selectFormByName } from '@solar/core/src/store/forms/selectors';
import { map } from 'rxjs';

@Component({
    selector: 'hb-reviews-modal',
    templateUrl: './reviews-modal.component.html',
    styleUrls: ['./reviews-modal.component.scss'],
    standalone: true,
    imports: [IonicModule, FormsModule, NgClass, ReactiveFormsModule, TranslateModule, FormConnectDirective, HeaderComponent, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsModalComponent implements OnInit {
    @Input() public userId: number;

    public formName = addReviewFormName;
    public formIsPending$ = this.store.select(selectFormByName(addReviewFormName)).pipe(map(r => !!r?.isPending));

    public form: FormGroup;

    protected readonly name;

    public reviews = [
        {
            rating: '1',
            icon: 'star-outline',
            className: 'star',
        },
        {
            rating: '2',
            icon: 'star-outline',
            className: 'star',
        },
        {
            rating: '3',
            icon: 'star-outline',
            className: 'star',
        },
        {
            rating: '4',
            icon: 'star-outline',
            className: 'star',
        },
        {
            rating: '5',
            icon: 'star-outline',
            className: 'star',
        },
    ];

    constructor(
        private readonly modalCtrl: ModalController,
        private readonly fb: FormBuilder,
        private readonly store: Store<IAppState>,
    ) {}

    public async cancel(): Promise<void> {
        await this.modalCtrl.dismiss();
    }

    public ngOnInit(): void {
        this.form = this.fb.group({
            user_id: [null, Validators.required],
            rating: [null, Validators.required],
            message: [null],
        });
    }

    public onClick(i: number): void {
        this.reviews = this.reviews.map((it, index) => {
            if (index <= i) {
                return { ...it, icon: 'star', className: 'active' };
            }

            return { ...it, icon: 'star-outline', className: 'no-active' };
        });

        this.form.patchValue({
            user_id: this.userId,
            rating: this.reviews[i].rating,
        });
    }

    public send(): void {
        this.store.dispatch(SendForm({ formName: this.formName, formState: { formData: this.form.value } }));
    }

    public async onSuccessForm(): Promise<void> {
        this.form.patchValue({
            message: null,
        });
        this.store.dispatch(LoadPage({ path: loadReviews, reqData: { user_id: this.userId } }));
        await this.modalCtrl.dismiss();
    }
}
