<ion-modal mode="ios" [trigger]="order.id" (willDismiss)="onWillDismiss($event)">
  <ng-template>

    <ion-header mode="ios">
      <hb-header title="MESSAGE">
        <ion-buttons slot="start">
          <ion-button (click)="cancel()">{{'CANCEL' | translate}}</ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
          @if (!isPending) {
            <ion-button (click)="confirm()" [strong]="true">{{'SEND' | translate}}</ion-button>
          }

          @if (isPending) {
            <ion-spinner size="large" color="primary" name="dots" class="m-r-10"></ion-spinner>
          }
        </ion-buttons>
      </hb-header>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-item>
        <ion-textarea
          [placeholder]="'ENTER_YOUR_MESSAGE' | translate"
          labelPlacement="stacked"
          type="text"
          [(ngModel)]="message"
          [autoGrow]="true"
        ></ion-textarea>
      </ion-item>
    </ion-content>

  </ng-template>
</ion-modal>
