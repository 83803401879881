import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { ICollectionResponse } from '../../../interfaces';
import { SlidesRes } from '../../../store/slides';

@Injectable({
    providedIn: 'root',
})
export class SlidesService {
    constructor(private readonly http: HttpClient) {}

    public addSlides(body): Observable<[]> {
        const formData = new FormData();
        formData.append('img', body.img);
        formData.append('type', body.type);
        formData.append('master_id', body.master_id);

        return this.http.post<[]>('/slides', formData);
    }

    public async deleteSlides(id: number): Promise<Object> {
        try {
            return await lastValueFrom(this.http.delete(`slides/${id}`));
        } catch (e) {
            console.log(e);
        }
    }

    public async patchSlides(id: number, body: { is_active: boolean }): Promise<Object> {
        try {
            return await lastValueFrom(this.http.patch(`slides/${id}`, body));
        } catch (e) {
            console.log(e);
        }
    }

    // public async getAllCategories(): Promise<CategoriesRes[]> {
    //     try {
    //         return await lastValueFrom(this.http.get(`categories`, { responseType: 'json' }));
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    public loadSlides(master_id: number): Observable<ICollectionResponse<SlidesRes[]>> {
        return this.http.get<ICollectionResponse<SlidesRes[]>>(`slides/${master_id}`);
    }

    public loadSlide(id: number): Observable<ICollectionResponse<SlidesRes>> {
        return this.http.get<ICollectionResponse<SlidesRes>>(`slides/${id}`);
    }
}
