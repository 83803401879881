import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { lastValueFrom, Observable } from 'rxjs';
import { PushSubsService } from '../';

@Injectable({
    providedIn: 'root',
})
export class MessagingService {
    constructor(private readonly afMessaging: AngularFireMessaging, private readonly pushSubsService: PushSubsService) {}

    public requestPermission(): Observable<string> {
        return this.afMessaging.requestToken.pipe(
            tap(async token => {
                if (token) {
                    await this.pushSubsService.addFCM({ fcm_token: token });
                }
            })
        );
    }

    public getMessages(): Observable<any> {
        return this.afMessaging.messages;
    }

    public async deleteToken(): Promise<void> {
        const fcm_token = await lastValueFrom(this.getToken());

        if (fcm_token) {
            await this.pushSubsService.deleteFCM(fcm_token);
        }
    }

    public getToken(): Observable<string | null> {
        return this.afMessaging.getToken;
    }
}
