import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateEffectMetadata } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { TypedAction } from '@ngrx/store/src/models';
import { IPagination } from '../../interfaces/pagination.interface';
export const LoadBatchPage = createAction('[Batch Page] Loading', props<{ path: string; filters?: any; reqData?: any }>());
export const LoadBatchPageSuccess = createAction('[Batch Page] Loaded success', props<{ path: string; data: any }>());
export const LoadBatchPageError = createAction(
    '[Batch Page] Loading fail',
    props<{ path: string; error: HttpErrorResponse; data?: any }>(),
);

export const LoadPage = createAction('[Page] Loading', props<{ path: string; filters?: any; reqData?: any }>());
export const LoadPageSuccess = createAction('[Page] Loaded success', props<{ path: string; data: any }>());
export const LoadPageError = createAction('[Page] Loading fail', props<{ path: string; error: HttpErrorResponse; data?: any }>());

export const LoadPagePush = createAction('[Page Push] Loading..', props<{ path: string; page: number; reqData?: any }>());
export const LoadPagePushSuccess = createAction('[Page Push] Success', props<{ path: string; data: any; page?: number; pages?: number }>());

export const ClearPage = createAction('[Page] Clear', props<{ path: string }>());

export const ClearAllPages = createAction('Clear all Pages');

export type ILoadPagePushEffect = Observable<
    | ({ path: string; error: HttpErrorResponse; data?: any } & TypedAction<'[Page] Loading fail'>)
    | ({ path: string; data: any; pagination: IPagination } & TypedAction<any>)
> &
    CreateEffectMetadata;

export type ILoadPageEffect = Observable<
    | ({ path: string; data: any } & TypedAction<'[Page] Loaded success'>)
    | ({ path: string; error: HttpErrorResponse; data?: any } & TypedAction<'[Page] Loading fail'>)
> &
    CreateEffectMetadata;

export type ISendFormEffect = Observable<
    | ({ formName: string; responseData: any } & TypedAction<'[FORM] Send Success'>)
    | ({ formName: string; error: HttpErrorResponse } & TypedAction<'[FORM] Send Error'>)
> &
    CreateEffectMetadata;
