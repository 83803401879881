import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IonModal } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HeaderComponent } from '@solar/shared';
import { FormsModule } from '@angular/forms';
import { DateService } from '@solar/core/src';

@Component({
    selector: 'hb-birthday',
    templateUrl: './birthday.component.html',
    styleUrls: ['./birthday.component.scss'],
    standalone: true,
    imports: [IonicModule, TranslateModule, HeaderComponent, FormsModule],
})
export class BirthdayComponent implements OnInit {
    @Input() public birthday: string;

    @Input() public modal: IonModal;

    constructor(
        public readonly translateService: TranslateService,
        private readonly dateService: DateService,
    ) {}

    public ngOnInit(): void {
        if (this.birthday) {
            this.birthday = this.dateService.getFormattedDateForServer(this.birthday);
        }
    }

    public cancel(): void {
        void this.modal.dismiss(null, 'cancel');
    }

    public confirm(): void {
        void this.modal.dismiss(this.birthday, 'confirm');
    }
}
