import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ServerError } from '../types';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { FirebaseAuthService, timeoutUtil, ToastService } from '../';
import { Router } from '@angular/router';

export interface InvalidParamDTO {
    name: string;
    reason: string[];
}

export interface ErrorDTO {
    fieldErrors?: InvalidParamDTO[];
    title?: string;
    type?: string;
}

const defaultServerError: ServerError = {
    message: 'Unknown error',
    fieldErrors: [],
};

const mapError = (error: ErrorDTO): ServerError => {
    return {
        message: error.title || 'Unknown error',
        fieldErrors: (error['fieldErrors'] || []).map(err => ({
            name: err.name,
            messages: err.reason,
        })),
    };
};

export enum API_ERROR_STATUSES {
    UNAUTHORIZED = 401,
    NO_PERMISSION = 403,
    SHOW_MESSAGE = 409,
    UNPROCESSABLE = 422,
    TO_MANY_REQUESTS = 429,
    SERVER_ERROR = 500,
}

@Injectable()
export class ErrorsMapInterceptor implements HttpInterceptor {
    constructor(private readonly injector: Injector) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(({ error, status }) => {
                const authService = this.injector.get(AuthService);
                const toastService = this.injector.get(ToastService);
                const fbService = this.injector.get(FirebaseAuthService);
                const router = this.injector.get(Router);

                const attempts = 0;

                const handleUnauthorized = async (attempts: number): Promise<void> => {
                    return new Promise(async resolve => {
                        if (attempts < 3) {
                            const firebaseToken = await fbService.getIdToken();
                            if (firebaseToken === null) {
                                await timeoutUtil();
                                await handleUnauthorized(attempts + 1);
                                resolve();
                            } else {
                                authService.loadAndSetUserData({
                                    ...authService?.principal,
                                    token: firebaseToken,
                                });
                                resolve();
                            }
                        } else {
                            // After 3 attempts, clear data
                            void authService.logout();
                            resolve();
                        }
                    });
                };
                // if user UNAUTHORIZED or token expired
                if (+status === API_ERROR_STATUSES.UNAUTHORIZED && authService?.principal) {
                    return from(handleUnauthorized(attempts)).pipe(
                        switchMap(() => {
                            // Update the request with the new token
                            const updatedRequest = request.clone({
                                setHeaders: {
                                    Authorization: `Bearer ${authService?.principal?.token}`,
                                },
                            });
                            // route to home if in login
                            if (router.url === '/login') {
                                void router.navigate(['/']);
                            }
                            // Retry the updated request
                            return next.handle(updatedRequest);
                        }),
                    );
                }

                if (error && error['message'] && error['fieldErrors']) {
                    return throwError(error);
                }

                // route to server-error page
                if (
                    status >= API_ERROR_STATUSES.SERVER_ERROR ||
                    status === API_ERROR_STATUSES.SHOW_MESSAGE ||
                    status === API_ERROR_STATUSES.TO_MANY_REQUESTS ||
                    status === API_ERROR_STATUSES.UNAUTHORIZED
                ) {
                    void toastService.presentToast(error.message || error.title || error.error, 2000, 'success');
                }

                return throwError({ error: error ? mapError(error) : defaultServerError });
            }),
        );
    }
}
