import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import { selectFormByName } from '@solar/core/src/store/forms/selectors';
import { Store } from '@ngrx/store';
import { UntypedFormGroup } from '@angular/forms';
import { IAppState } from '@solar/interfaces';
import { IonicModule } from '@ionic/angular';
import { AsyncPipe, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export interface ISubmit {
    label: string;
    color?: string;
    shape?: string;
    size?: string;
}

@Component({
    selector: 'hb-submit',
    templateUrl: './submit.component.html',
    styleUrls: ['./submit.component.scss'],
    standalone: true,
    imports: [IonicModule, NgClass, AsyncPipe, TranslateModule],
})
export class SubmitComponent implements OnInit {
    @Input()
    public formName?: string;

    @Input()
    public form?: UntypedFormGroup;

    @Input()
    public isDisabled?: boolean = false;

    @Input()
    public configs?: ISubmit;

    public formIsPending$?: Observable<boolean>;

    @Output()
    public readonly onSubmit = new EventEmitter();

    constructor(private readonly store: Store<IAppState>) {}

    public ngOnInit(): void {
        if (this.formName) {
            this.formIsPending$ = this.store.select(selectFormByName(this.formName)).pipe(map(r => !!r?.isPending));
        }
    }
}
