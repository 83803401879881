import { Injectable } from '@angular/core';
import { signal } from '@angular/core';
import { WritableSignal } from '@angular/core';
import { CategoryServicesRes } from '@solar/core/src/store';
import { CardModelsEnum } from '@solar/enums';

@Injectable({
    providedIn: 'root',
})
export class BasketService {
    public readonly model: WritableSignal<CardModelsEnum | null> = signal(null);
    private readonly selectedItem: WritableSignal<CategoryServicesRes | null> = signal(null);
    private readonly items: WritableSignal<CategoryServicesRes[]> = signal([]);

    constructor() {}

    public setFullBasketItems(items: CategoryServicesRes[]): void {
        this.items.set(items);
    }
    public setBasketItems(items: CategoryServicesRes[]): void {
        this.items.update(currItems => [...currItems, ...items]);
    }

    public setBasketItem(item: CategoryServicesRes): void {
        this.items.update(items => [...items, item]);
    }

    public get getItems(): WritableSignal<CategoryServicesRes[]> {
        return this.items;
    }

    public popItem(): void {
        this.items.update(items => items.slice(0, -1));
    }

    public itCountInBasket(service: CategoryServicesRes): CategoryServicesRes[] {
        return this.items().filter(it => it.id === service.id);
    }

    public removeItemsById(id: number): void {
        this.items.update(items => items.filter(item => item.id !== id));
    }

    public set selectedItemValue(value: CategoryServicesRes | null) {
        this.selectedItem.set(value);
    }

    public set modelValue(value: CardModelsEnum) {
        this.model.set(value);
    }

    public clearAll(): void {
        this.items.set([]);
        this.model.set(null);
        this.selectedItem.set(null);
    }
}
