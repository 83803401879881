import { createReducer, on } from '@ngrx/store';
import { IFormState, initFormState, IPayloadFormState } from '../core-store.interface';
import { AddForm, ClearAllForms, ResetForm, ResetFormError, SendForm, SendFormError, SendFormSuccess, SetFormData } from './actions';
import { get } from 'lodash';

export const FormsReducers = createReducer(
    {} as unknown as IPayloadFormState,
    on(AddForm, (state, { formName, formData }) => {
        if (state && state[formName as keyof typeof state]) {
            return state;
        }

        const stateKey = state[formName as keyof typeof state] as Element;
        const stateData = get(stateKey, 'formState.formData') as object;
        const data = { ...formData } as object;

        if (data && stateData) {
            for (const k in data) {
                if (!data[k] && stateData[k]) {
                    data[k] = stateData[k as keyof typeof stateData];
                }
            }
        }
        return {
            [formName]: {
                formName: formName as string,
                formState: {
                    ...initFormState,
                    formData: { ...data },
                    isPending: false,
                    isSuccess: false,
                    hasResponse: false,
                },
            },
            ...state,
        };
    }),

    on(SendForm, (state, { formName, formState }) => ({
        ...state,
        [formName]: {
            formState: {
                ...get(state[formName], 'formState'),
                ...formState,
                isPending: true,
                isSuccess: false,
                hasResponse: false,
            },
        },
    })),

    on(SendFormSuccess, (state, { formName, responseData }) => ({
        ...state,
        [formName]: {
            formName,
            formState: {
                ...get(state[formName], 'formState'),
                isPending: false,
                isSuccess: true,
                hasResponse: true,
                responseData,
            },
        },
    })),

    on(SendFormError, (state, { formName, error }) => ({
        ...state,
        [formName]: {
            formName,
            formState: {
                ...get(state[formName], 'formState'),
                isPending: false,
                hasResponse: true,
                isError: true,
                error,
            },
        },
    })),

    on(SetFormData, (state, { formName, formData }) => {
        return {
            ...state,
            [formName]: {
                formName,
                formState: {
                    ...get(state[formName as keyof typeof state] as Element, 'formState'),
                    formData: {
                        ...get(state[formName], 'formState.formData'),
                        ...formData,
                    },
                    isPending: false,
                    isSuccess: false,
                    hasResponse: false,
                } as IFormState,
            },
        };
    }),

    on(ResetForm, (state, { formName }) => ({
        ...state,
        [formName]: {
            formName,
            formState: {
                ...initFormState,
                formData: {},
            },
        },
    })),

    on(ResetFormError, (state, { formName }) => ({
        ...state,
        [formName]: {
            formName,
            formState: {
                formData: get(state[formName], 'formState.formData'),
                isPending: false,
                hasResponse: true,
                isError: false,
                error: null,
            },
        },
    })),

    on(ClearAllForms, (): any => ({})),
);
