<ion-header>
  <hb-header title="BIRTH_DATE">
    <ion-buttons slot="end">
      <ion-button (click)="confirm()" [strong]="true">{{'SAVE' | translate}}</ion-button>
    </ion-buttons>
  </hb-header>
</ion-header>

    <div class="inner-content">
      <ion-datetime
        [locale]="translateService.currentLang"
        min="1960-01-01"
        max="2010"
        color="pink"
        mode="ios"
        [(ngModel)]="birthday"
        presentation="date"
        [preferWheel]="true"
      ></ion-datetime>
    </div>
