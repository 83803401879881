import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { normalizeTime } from '@solar/core/src/utils/date';

export interface IHours {
    id?: number;
    colSize: string;
    label: string;
    isDisabled: boolean;
    checked?: boolean;
    order?: IOrders;
}

@Component({
    selector: 'hb-hours-grid',
    templateUrl: './hours-grid.component.html',
    styleUrls: ['./hours-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, IonicModule, TranslateModule],
})
export class HoursGridComponent {
    @Input() public hours: IHours[];

    @Input() public selectedTime: string;

    @Output() public selected: EventEmitter<string> = new EventEmitter();

    public onSelectTime(time: string): void {
        this.selectedTime = time;
        this.selected.emit(normalizeTime(this.selectedTime));
    }
}
