import { Injectable } from '@angular/core';
import { signal } from '@angular/core';
import { WritableSignal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UserSearchStateService {
    public readonly items: WritableSignal<number[]> = signal([]);

    constructor() {}
}
