import { Injectable } from '@angular/core';
import { FormEffects, LoadPage, PagesEffects } from '../../store';
import { IMessage, MessagesService, MessagesWsService } from '../../services';
import { IAppState, IRestOptions } from '@solar/core/src';
import { map } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import { Store } from '@ngrx/store';

export const addMessage = 'addMessage';
export const loadMessages = 'loadMessages';
export const loadUnread = 'loadUnread';
export const loadDirects = 'loadDirects';
export const deleteMessage = 'deleteMessage';
export const deleteAllMessages = 'deleteAllMessages';

@Injectable()
export class MessagesEffects {
    constructor(
        private readonly formEffects: FormEffects,
        private readonly pagesEffects: PagesEffects,
        private readonly messagesService: MessagesService,
        private readonly socket: Socket,
        private readonly messagesWsService: MessagesWsService,
        private readonly store: Store<IAppState>,
    ) {}

    public readonly $addMessage = this.formEffects.getSendFormEffect(addMessage, formState => {
        return this.messagesService.create(formState.formData).pipe(
            map((it: IMessage) => {
                this.messagesWsService.send(it, formState.formData.receiver);

                return it;
            }),
        );
    });

    public readonly $loadMessages = this.pagesEffects.getLoadPageEffect(loadMessages, ({ reqData }) => {
        return this.messagesService.getAll(reqData).pipe(map(res => ({ ...res, data: res.data.reverse() })));
    });

    public readonly $loadUnread = this.pagesEffects.getLoadPageEffect(loadUnread, () => {
        return this.messagesService.getAllUnread();
    });

    public readonly $loadMessagesPush = this.pagesEffects.getLoadPagePushEffect(loadMessages, ({ page, reqData }: IRestOptions) => {
        return this.messagesService.getAll({ page, ...reqData }).pipe(map(res => ({ ...res, data: res.data.reverse() })));
    });

    public readonly $loadDirects = this.pagesEffects.getLoadPageEffect(loadDirects, () => {
        return this.messagesService.getAllDirects();
    });

    public readonly $deleteMessage = this.formEffects.getSendFormEffect(deleteMessage, formState => {
        return this.messagesService.deleteItem(formState?.formData?.id).pipe(
            map(() => {
                this.socket.emit('delete_message', formState?.formData?.id);
            }),
        );
    });

    public readonly $deleteAllMessages = this.formEffects.getSendFormEffect(deleteAllMessages, formState =>
        this.messagesService.deleteAllMessages(formState?.formData?.user_id).pipe(
            map(() => {
                this.store.dispatch(LoadPage({ path: loadDirects }));
                this.socket.emit('delete_message', formState?.formData?.user_id);
            }),
        ),
    );
}
