import { Injectable } from '@angular/core';
import { PagesEffects } from '../pages';
import { FormEffects } from '../forms';
import { AddressesService } from '../../services';
import { addAddressesFormName, editAddressesFormName } from '@solar/core/src/store/addresses/selectors';

@Injectable()
export class AddressesEffects {
    public readonly $addAddress = this.formEffects.getSendFormEffect(addAddressesFormName, formState => {
        return this.addressesService.addAddress(formState.formData);
    });

    public readonly $edit = this.formEffects.getSendFormEffect(editAddressesFormName, formState => {
        return this.addressesService.editAddress(formState.formData);
    });

    constructor(
        private readonly pagesEffects: PagesEffects,
        private readonly formEffects: FormEffects,
        private readonly addressesService: AddressesService,
    ) {}
}
