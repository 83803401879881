import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IPhoneCodesDto } from './phone-codes.dto';

@Injectable({
    providedIn: 'root',
})
export class PhoneCodesService {
    constructor(private readonly http: HttpClient) {}

    public verifyPhone(body: IPhoneCodesDto): Observable<[]> {
        return this.http.post<[]>(`/verify-phone`, body);
    }

    public sendSmsCode(body: IPhoneCodesDto): Observable<[]> {
        return this.http.post<[]>(`/sms-codes`, body);
    }

    public verifyCode(body: IPhoneCodesDto): Observable<IPhoneCodesDto> {
        return this.http.post<IPhoneCodesDto>(`/check-sms-code`, body);
    }
}
