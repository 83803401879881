@if (messages()?.data?.length) {
  @if (messages()?.data?.length > 19 && lastLoaded) {
    <ion-infinite-scroll threshold="230px" position="top" (ionInfinite)="onIonInfinite($event, messages().page, messages().pages)">
      <ion-infinite-scroll-content loadingSpinner="lines" loadingText=""></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  }
  <div class="m-t-10 ion-margin-bottom">
    @for (it of messages()?.data; track it) {
      <hb-chat-modal-list-item [item]="it" [categoryId]="categoryId"></hb-chat-modal-list-item>
    }
  </div>
} @else {
  <ion-card>
    <ion-card-content>
      <ion-text color="medium">
        <div class="ion-text-center">
          {{'LIST_EMPTY' | translate}}
        </div>
      </ion-text>
    </ion-card-content>
  </ion-card>
}

