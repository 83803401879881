import { createSelector } from '@ngrx/store';
import { IAppState } from '../../interfaces';
import { get } from 'lodash';

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const formsStore = (state: IAppState) => state.core.forms;

export const selectForms = createSelector(formsStore, forms => forms);

export const selectFormByName = (name: string) => createSelector(formsStore, forms => get(forms, `${name}.formState`));
