import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionSheetButton } from '@ionic/angular';
import { UserRoleService } from '@solar/core/src/services/user-role/user-role.service';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { OrderStatusesEnum } from '@solar/enums/order-statuses.enum';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class OrderActionService {
    constructor(
        private readonly translate: TranslateService,
        private readonly userRoleService: UserRoleService,
        private readonly router: Router,
    ) {}

    public async getOrderActions(order: IOrders): Promise<ActionSheetButton[]> {
        const buttons: ActionSheetButton[] = [
            {
                text: this.translate.instant('EDIT'),
                data: {
                    action: 'edit',
                },
                handler: () => {}, // Обработчик будет добавлен в компоненте
            },
            {
                text: this.translate.instant('CLOSE'),
                role: 'cancel',
                data: {
                    action: 'cancel',
                },
            },
        ];

        if (order.order_status_id !== OrderStatusesEnum.PAID && order.order_status_id !== OrderStatusesEnum.CANCELLED) {
            buttons.unshift({
                text: this.translate.instant('Pay'),
                data: {
                    action: 'pay',
                },
                handler: () => {},
            });

            buttons.push({
                text: this.translate.instant('CANCEL'),
                role: 'destructive',
                data: {
                    action: 'delete',
                },
                handler: () => {},
            });
        }

        if (
            (this.userRoleService.isAdminOrMaster && order.order_status_id === OrderStatusesEnum.IN_PROGRESS) ||
            (this.userRoleService.isAdminOrMaster && order.order_status_id === OrderStatusesEnum.PENDING_ACCEPTANCE)
        ) {
            buttons.unshift({
                text: this.translate.instant('ACCEPT'),
                handler: () => {}, // Обработчик будет добавлен в компоненте
            });
        }

        return buttons;
    }

    public goToEditOrder(order: IOrders, routerLink?: string): void {
        if (this.userRoleService.isAdmin) {
            void this.router.navigate([routerLink ? routerLink : '/admin/edit-order', order.id], { state: { order: order } });
            return;
        }

        void this.router.navigate([routerLink ? routerLink : '/orders', order.id], { state: { order: order } });
    }
}
