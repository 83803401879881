import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AlertController, IonicModule } from '@ionic/angular';
import { IAppState, IMessage } from '@solar/core/src';
import { Store } from '@ngrx/store';
import { deleteMessage, SendForm } from '@solar/core/src/store';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedDatePipe } from '../../../../directives';
import { AvatarComponent } from '../../../../components/stateless/avatar/avatar.component';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';

@Component({
    selector: 'hb-chat-modal-list-item',
    templateUrl: './chat-modal-list-item.component.html',
    styleUrls: ['./chat-modal-list-item.component.scss'],
    standalone: true,
    imports: [IonicModule, LocalizedDatePipe, AvatarComponent, RouterModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatModalListItemComponent implements OnInit {
    @Input() public item: IMessage;
    @Input() public categoryId: number;

    constructor(
        private readonly store: Store<IAppState>,
        private readonly alertController: AlertController,
        private readonly authService: AuthService,
        private readonly translate: TranslateService,
        private readonly router: Router,
    ) {}

    public ngOnInit(): void {}

    public trackByFn(index: number): number {
        return index;
    }

    protected get myId(): number {
        return this.authService?.principal?.id;
    }

    public async onDelete(id: number): Promise<void> {
        const alert = await this.alertController.create({
            header: this.translate.instant('SURE_DELETE_THIS_MSG'),
            mode: 'ios',
            buttons: [
                {
                    text: this.translate.instant('CANCEL'),
                    role: 'cancel',
                    handler: () => {},
                },
                {
                    text: this.translate.instant('OK'),
                    role: 'confirm',
                    handler: () => {
                        this.store.dispatch(
                            SendForm({ formName: deleteMessage, formState: { formData: { id, categoryId: this.categoryId } } }),
                        );
                    },
                },
            ],
        });

        await alert.present();
    }

    protected formatMessage(message: string): any[] {
        const orderPattern = /\[nav:([^\]]*?):nav\|(.*?)\]/g;
        const parts = [];
        let lastIndex = 0;

        message.replace(orderPattern, (match, p1, text, offset) => {
            if (offset > lastIndex) {
                parts.push({ type: 'text', content: message.substring(lastIndex, offset).replace(/\n/g, '<br>') });
            }
            const [url, params] = p1.split('?');
            const queryParams = params ? this.sanitizeQueryParams(params) : {};
            parts.push({ type: 'link', url, queryParams, text });
            lastIndex = offset + match.length;
            return '';
        });

        if (lastIndex < message.length) {
            parts.push({ type: 'text', content: message.substring(lastIndex).replace(/\n/g, '<br>') });
        }

        return parts;
    }

    private sanitizeQueryParams(queryParams: string): any {
        const params = new URLSearchParams(queryParams);
        const paramsObj = {};
        params.forEach((value, key) => {
            paramsObj[key] = value;
        });
        return paramsObj;
    }

    protected navigate(url: string, queryParams: any, event: Event): void {
        event.preventDefault(); // Prevent the default anchor behavior
        void this.router.navigate([url], { queryParams });
    }
}
