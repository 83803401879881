import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ENV, INDEX_URL } from './core.tokens';
import { ICoreConfig } from './core.interface';
import { environment } from '../../../src/environments/environment';
import { AuthModule } from './auth';
import { AuthServiceConfig } from './auth/auth.service';
import { reducers } from './store/main';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
    FormEffects,
    OrdersEffects,
    PagesEffects,
    SlidesEffects,
    SmsCodesEffects,
    UserEffects,
    ReviewsEffects,
    MessagesEffects,
    WorkingHoursEffects,
    PaymentsEffects,
    DescriptionsEffects,
    MastersEffects,
    CloseDayEffects,
    LoginEffects,
    PasswordResetEffects,
    SignUpEffects,
    CategoryServicesEffects,
    BalancesEffects,
    AddressesEffects,
} from './store';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CategoriesEffects } from './store/categories';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { BatchEffects } from './store/batch';
const urlObject = new URL(environment.api);
const secureProtocol = urlObject.protocol === 'https:' ? 'wss:' : 'ws:';
const socketIoConfig: SocketIoConfig = { url: `${secureProtocol}//${urlObject.host}`, options: { transports: ['websocket'] } };
const config: ICoreConfig = {
    indexUrl: '/',
    env: environment,
};

const authConfig: AuthServiceConfig = {
    baseUrl: environment.api,
    storageTokenKey: 'token',
    storagePrincipalKey: 'principal',
};

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        AuthModule.forRoot(authConfig),
        StoreModule.forFeature('core', reducers),
        EffectsModule.forFeature([
            FormEffects,
            PagesEffects,
            CategoryServicesEffects,
            UserEffects,
            CategoriesEffects,
            OrdersEffects,
            SmsCodesEffects,
            SlidesEffects,
            ReviewsEffects,
            MessagesEffects,
            WorkingHoursEffects,
            PaymentsEffects,
            DescriptionsEffects,
            BatchEffects,
            MastersEffects,
            CloseDayEffects,
            LoginEffects,
            PasswordResetEffects,
            SignUpEffects,
            BalancesEffects,
            AddressesEffects,
        ]),
        TranslateModule,
        SocketIoModule.forRoot(socketIoConfig),
    ],
    providers: [
        {
            provide: ENV,
            useValue: config.env,
        },
        {
            provide: INDEX_URL,
            useValue: config.indexUrl,
        },
    ],
})
export class HamboCoreModule {}
