import { UserDTO } from '../../auth/auth.dto';

const mapName = (userDTO: UserDTO): string => [userDTO.name].filter(f => !!f).join(' ');

export const mapUserDTO =
    (id: number) =>
    (userDTO: UserDTO): any => ({
        id,
        name: mapName(userDTO),
    });
