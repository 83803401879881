import { Injectable } from '@angular/core';
import { FormEffects } from '../../store';
import { CloseDayService, IDayClose } from '../../services/rest/close-day';

export const closeDayFormName = 'closeDayFormName';

@Injectable()
export class CloseDayEffects {
    public readonly $closeDay = this.formEffects.getSendFormEffect(closeDayFormName, (formState: { formData: IDayClose }) => {
        const hours: string = formState.formData.hours.toString();

        return this.dayCloseService.closeDay({ ...formState.formData, hours });
    });

    constructor(private readonly dayCloseService: CloseDayService, private readonly formEffects: FormEffects) {}
}
