import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'localizedCurrency',
    standalone: true,
})
export class LocalizedCurrencyPipe implements PipeTransform {
    constructor(
        private readonly currency: CurrencyPipe,
        private readonly translateService: TranslateService,
    ) {}

    public transform(
        value: number | string,
        currency: string = 'EUR',
        display: string = 'symbol',
        valueTo: number | string | null = null,
    ): string | null {
        const currentLang = this.translateService.currentLang;

        let formattedValue: string | null = null;
        let formattedValueTo: string | null = null;

        const numericValue = parseFloat(value.toString());

        if (!isNaN(numericValue)) {
            if (Number.isInteger(numericValue)) {
                formattedValue = this.currency.transform(numericValue, currency, display, '1.0-0', currentLang);
            } else {
                formattedValue = this.currency.transform(numericValue, currency, display, '1.2-2', currentLang);
            }
        }

        if (valueTo !== null) {
            const numericValueTo = parseFloat(valueTo.toString());
            if (!isNaN(numericValueTo)) {
                formattedValueTo = this.currency.transform(numericValueTo, currency, display, '1.0-0', currentLang);
            }
        }

        // Заменяем точки на запятые в результате
        if (formattedValue !== null) {
            formattedValue = formattedValue.replace(/\./g, ',');
        }
        if (formattedValueTo !== null) {
            formattedValueTo = formattedValueTo.replace(/\./g, ',');
        }

        // Ручная замена 'UAH' на символ '₴'
        if (currency === 'UAH') {
            if (formattedValue !== null) {
                formattedValue = formattedValue.replace('UAH', '₴');
            }
            if (formattedValueTo !== null) {
                formattedValueTo = formattedValueTo.replace('UAH', '₴');
            }
        }

        // Объединяем диапазон значений, если `valueTo` присутствует
        if (formattedValue !== null && formattedValueTo !== null) {
            formattedValue += ` - ${formattedValueTo}`;
        }

        return formattedValue;
    }
}
