<ion-header>
  <hb-header title="{{'CODE_SENT_TO' | translate}} {{phone}}">
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss()">
        <ion-icon color="dark" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </hb-header>
</ion-header>

<ion-content>
  <form [formConnect]="formName" [formGroup]="form" (onSuccessForm)="onSuccess($event)">
    @if (description) {
      <div class="ion-margin">
        <ion-text color="medium"><em>{{description | translate}}</em></ion-text>
      </div>
    }
      <ion-list mode="ios" [inset]="true">
          <ion-item color="light">
            <ion-input
              [label]="'CODE' | translate"
              labelPlacement="floating"
              type="number"
              formControlName="code"
              minlength="6"
              maxlength="6"
            ></ion-input>
          </ion-item>
      </ion-list>
      <div class="ion-margin-horizontal" [appInputError]="form.get('code')"></div>

    <div class="ion-margin-horizontal">
      <hb-submit [form]="form" [formName]="formName" [configs]="{label: 'OK'}"></hb-submit>
    </div>
  </form>

  @if (timer$ | async; as t) {
    <div class="ion-margin">
      <ion-text color="medium">
        {{ 'You can request the code again in' | translate }}
        <strong>
          {{ t * 1000 | date : 'mm:ss' }}
        </strong>
      </ion-text>
    </div>
  }

  @if ((timer$ | async) <= 0) {
    <ion-button
      mode="ios"
      expand="block"
      (click)="onSendAgain()"
      fill="clear"
    >
      {{ 'Request the code again' | translate }}
    </ion-button>
  }
</ion-content>
