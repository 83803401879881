@if ((typesTabsFilters$ | async)?.length > 1) {
  <ion-toolbar mode="ios" class="ion-no-padding">
    <ion-segment class="ion-margin-horizontal" (ionChange)="onSelect($event)" [scrollable]="true" value="all">
      <ion-segment-button value="all">
        {{'ALL' | translate}}
      </ion-segment-button>
      @for (k of (typesTabsFilters$ | async); track k) {
        <ion-segment-button [value]="k">
          {{k | serverTranslate}}
        </ion-segment-button>
      }
    </ion-segment>
  </ion-toolbar>
}
