import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IAppState } from '@solar/core/src';
import { Store } from '@ngrx/store';
import { ClearPage, LoadPage, selectPageByName } from '@solar/core/src/store';
import { loadReviews } from '@solar/core/src/store/reviews';
import { AsyncPipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ReviewsModalComponent } from '../reviews-modal/reviews-modal.component';
import { Router } from '@angular/router';
import { DateAgoPipe, FullNamePipe } from '../../../pipes';
import { AvatarComponent } from '../../../components/stateless/avatar/avatar.component';
import { IsAuthorizedDirective } from '../../../directives';
import { StarsComponent } from '../../../components/statefull/stars/stars.component';

@Component({
    selector: 'hb-reviews-list',
    templateUrl: './reviews-list.component.html',
    styleUrls: ['./reviews-list.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        IonicModule,
        ReviewsModalComponent,
        TranslateModule,
        DateAgoPipe,
        AvatarComponent,
        IsAuthorizedDirective,
        StarsComponent,
        FullNamePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsListComponent implements OnInit, OnDestroy {
    @Input() public userId: number;

    public reviews$ = this.store.select(selectPageByName(loadReviews));

    constructor(
        private readonly store: Store<IAppState>,
        private readonly router: Router,
    ) {}

    public ngOnInit(): void {
        this.store.dispatch(LoadPage({ path: loadReviews, reqData: { user_id: this.userId } }));
    }

    protected onRouteToLogin(): void {
        void this.router.navigate(['/login']);
    }

    public ngOnDestroy(): void {
        this.store.dispatch(ClearPage({ path: loadReviews }));
    }
}
