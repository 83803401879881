import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, signal } from '@angular/core';
import { InfiniteScrollCustomEvent, IonicModule } from '@ionic/angular';
import { FormGroup } from '@angular/forms';
import { IAppState, IMessage, timeoutUtil } from '@solar/core/src';
import { Store } from '@ngrx/store';
import { addMessage, loadMessages, LoadPage, LoadPagePush, selectPageByName } from '@solar/core/src/store';
import { TranslateModule } from '@ngx-translate/core';
import { ChatModalListItemComponent } from '@solar/shared/standalone/components/chat-modal-list/chat-modal-list-item/chat-modal-list-item.component';

@Component({
    selector: 'hb-chat-modal-list',
    templateUrl: './chat-modal-list.component.html',
    styleUrls: ['./chat-modal-list.component.scss'],
    standalone: true,
    imports: [IonicModule, TranslateModule, ChatModalListItemComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatModalListComponent implements OnInit {
    @Input() public content: any;
    @Input() public categoryId: number;

    public formName: string = addMessage;

    public form: FormGroup;

    public lastLoaded = false;

    protected messages = signal<{ data: IMessage[]; page: number; pages: number }>(null);

    constructor(
        private readonly store: Store<IAppState>,
        private readonly ch: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.loadMessages();
        this.subscribeToMessages();
    }

    private loadMessages(): void {
        this.store.dispatch(LoadPage({ path: loadMessages, reqData: { receiver: this.categoryId } }));
    }

    private subscribeToMessages(): void {
        this.store.select(selectPageByName(loadMessages)).subscribe(res => {
            this.messages.set(res);

            if (this.messages()?.data?.length > res?.size) {
                this.messages.set({ ...res, data: [...this.messages()?.data].sort((a, b) => a.id - b.id) });
            }

            this.onScrollBottom();
            this.ch.detectChanges();
        });
    }

    public onScrollBottom(): void {
        if (!this.lastLoaded) {
            this.content.scrollToBottom(50);
            void timeoutUtil().then(() => {
                this.content.scrollToBottom(50);
                this.lastLoaded = true;
                this.ch.detectChanges();
            });
        }
    }

    public trackByFn(index: number): number {
        return index;
    }
    public onPushData(res): void {
        this.store.dispatch(LoadPagePush({ page: ++res.page, path: loadMessages, reqData: { receiver: this.categoryId } }));

        void timeoutUtil().then(() => {
            void (res.ev as InfiniteScrollCustomEvent).target.complete();
        });
    }

    public onIonInfinite(ev, page: number, pages?: number): void {
        if (page >= pages) {
            void timeoutUtil().then(() => {
                void (ev as InfiniteScrollCustomEvent).target.complete();
            });
            return;
        }

        this.onPushData({ ev, page });
    }
}
