@if (configs) {
  <ion-button
    [color]="configs.color"
    [size]="configs.size"
    class="text-transform-none bg-pink-ion"
    type="submit"
    expand="block"
    [disabled]="(!form.valid || (formIsPending$ | async)) || isDisabled"
    (click)="onSubmit.emit()"
    mode="ios"
    >
    @if ((formIsPending$ | async) === false) {
      {{ configs.label | translate }}
    } @else {
      <ion-spinner name="dots" color="white"></ion-spinner>
    }
  </ion-button>
}
