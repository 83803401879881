import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { parse, format } from 'date-fns';
import { ru, enUS } from 'date-fns/locale';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    constructor(private readonly translateService: TranslateService) {}
    public getCurrentMonth(date?: string): string {
        let dateObject = new Date();
        const currentLang = this.translateService.currentLang; // определите текущий язык

        if (date) {
            dateObject = new Date(date);
        }

        // Определите локаль на основе текущего языка
        let locale;
        switch (currentLang) {
            case 'ru':
                locale = ru;
                break;
            case 'en':
                locale = enUS;
                break;
            default:
                locale = enUS; // язык по умолчанию
        }

        return format(dateObject, 'MMMM', { locale });
    }

    public dateTranslate(value: Date | string, format = 'mediumDate'): string {
        const datePipe = new DatePipe(this.translateService.currentLang || 'en');
        return datePipe.transform(value, format);
    }

    public getFormattedDateForServer(localizedDate: string): string {
        if (!localizedDate) {
            throw new Error('Localized date is required');
        }

        const lang = this.translateService.currentLang;
        const dateFormat = this.setDateFormat(lang);
        const locale = lang === 'ru' ? ru : enUS;
        const parsedDate = parse(localizedDate, dateFormat, new Date(), { locale });

        if (isNaN(parsedDate.getTime())) {
            throw new Error('Invalid date: ' + localizedDate);
        }

        return format(parsedDate, 'yyyy-MM-dd');
    }

    public setDateFormat(lang: string): string {
        if (lang === 'ru') {
            return 'd MMM yyyy г.';
        } else {
            return 'MMM d, yyyy';
        }
    }
}
