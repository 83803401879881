import { Component, ChangeDetectionStrategy, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { IonicModule, IonModal } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayEventDetail } from '@ionic/core/components';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { FormsModule } from '@angular/forms';
import { IAppState, ToastService } from '@solar/core/src';
import { addMessage, SendForm } from '@solar/core/src/store';
import { Store } from '@ngrx/store';
import { selectFormByName } from '@solar/core/src/store/forms/selectors';
import { HeaderComponent } from '../../../components/stateless/header/header.component';

@Component({
    selector: 'hb-message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss'],
    standalone: true,
    imports: [IonicModule, TranslateModule, FormsModule, HeaderComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageModalComponent {
    @Input() public order: IOrders;

    public message: string;

    @ViewChild(IonModal) protected modal: IonModal;

    @Output() private readonly res = new EventEmitter();

    protected isPending = false;

    constructor(private readonly store: Store<IAppState>, private readonly toastService: ToastService) {}

    public cancel(): void {
        void this.modal.dismiss(null, 'cancel');
    }

    public confirm(): void {
        this.isPending = true;

        this.store.dispatch(
            SendForm({
                formName: addMessage,
                formState: {
                    formData: {
                        message: this.message,
                        order_id: this.order.id,
                        send_sms: true,
                        phone: this.order.phone,
                        receiver: this.order.user_id,
                    },
                },
            })
        );

        this.store.select(selectFormByName(addMessage)).subscribe(form => {
            if (form.hasResponse && form.responseData) {
                this.isPending = false;
                this.message = '';
                void this.modal.dismiss(null, 'confirm');
            }
        });
    }

    public onWillDismiss(event: Event): void {
        const ev = event as CustomEvent<OverlayEventDetail<string>>;
        if (ev.detail.role === 'confirm') {
            this.res.emit();
            void this.toastService.presentToast('YOUR_MESSAGE_SENT');
        }
    }
}
