import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Add 'Content-Type': 'application/json' to all requests
 */
export class JsonInterceptor implements HttpInterceptor {
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const localLang = localStorage.getItem('lang');
        const userLang = localLang ? localLang : navigator.language;
        const lang = userLang.includes('ru') ? 'ru-RU' : 'en-US';

        request = request.clone({
            setHeaders: {
                'Accept-Language': lang,
            },
        });

        return next.handle(request);
    }
}
