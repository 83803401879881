import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(
        private readonly toastController: ToastController,
        private readonly translateService: TranslateService,
    ) {}

    public async presentToast(
        message: string,
        duration = 3000,
        type = 'success',
        position: 'top' | 'bottom' | 'middle' = 'bottom',
        isDismiss = false,
    ) {
        const isToast = await this.toastController.getTop();

        if (!isToast && message) {
            const toast = await this.toastController.create({
                message: this.translateService.instant(message),
                duration,
                cssClass: type === 'error' ? 'text-error' : type,
                position,
                color: 'light',
                buttons: isDismiss && [
                    {
                        text: this.translateService.instant('OK'),
                        role: 'cancel',
                    },
                ],
                swipeGesture: 'vertical',
                translucent: true,
                mode: 'ios',
                positionAnchor: 'footer',
            });

            await toast.present();
        }
    }
}
