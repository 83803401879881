import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { OrdersService } from '../../../../../../src/app/core/services';
import { ActionSheetController, AlertController, InfiniteScrollCustomEvent, IonicModule, ModalController } from '@ionic/angular';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserRolesEnum } from '@solar/enums/user-roles.enum';
import { OrderStatusesEnum } from '@solar/enums/order-statuses.enum';
import { IPaymentData, IPaymentsItem, OrderActionService, timeoutUtil } from '@solar/core/src';
import { MessageModalComponent } from '../../../standalone/components/message-modal/message-modal.component';
import { AdminOrdersListItemComponent } from './admin-orders-list-item/admin-orders-list-item.component';
import { ShowOnlyForDirective } from '../../../directives/show-only-for.directive';
import { PaymentService } from '@solar/core/src/services/payment';
import { PaymentsListModal } from '../../../../../../src/app/pages/master-order-card/order-card-form/payments-list-modal/payments-list-modal.component';
import { SelectedPaymentEnum } from '@solar/enums/payments.enum';
import { RouterModule } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'hb-admin-orders-list',
    templateUrl: './admin-orders-list.component.html',
    styleUrls: ['./admin-orders-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        IonicModule,
        ShowOnlyForDirective,
        AdminOrdersListItemComponent,
        MessageModalComponent,
        TranslateModule,
        PaymentsListModal,
        RouterModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminOrdersListComponent {
    protected isModalOpen = false;
    protected selectedItem: IOrders;
    @Output() public updateData = new EventEmitter();
    @Output() public loadDataPush = new EventEmitter();
    @Input() public orders: {
        pages?: number;
        page?: number;
        data: any[] | null;
        isPending: boolean;
    };

    public selectedFruits: string = 'cash';

    public fruits: IPaymentsItem[] = [{ text: 'CASH', value: SelectedPaymentEnum.CASH, icon: 'cash-outline' }];

    @Output() public search = new EventEmitter<string>();
    constructor(
        private readonly ordersService: OrdersService,
        private readonly alertController: AlertController,
        private readonly translate: TranslateService,
        private readonly payService: PaymentService,
        private readonly modalCtrl: ModalController,
        private readonly actionSheetCtrl: ActionSheetController,
        private readonly ch: ChangeDetectorRef,
        private readonly ordersActionService: OrderActionService,
    ) {}

    public trackByFn(index: number, item: { id: number }): number {
        return item ? item.id : index;
    }
    protected setOpen(isOpen: boolean, it?: IOrders): void {
        if (it) {
            this.selectedItem = it;
        }

        this.isModalOpen = isOpen;
        this.ch.detectChanges();
    }

    public async onCancel(id: number): Promise<void> {
        const alert = await this.alertController.create({
            header: this.translate.instant('SURE_TO_CANCEL_MSG'),
            mode: 'ios',
            buttons: [
                {
                    text: this.translate.instant('NO'),
                    role: 'cancel',
                    handler: () => {},
                },
                {
                    text: this.translate.instant('YES'),
                    role: 'confirm',
                    handler: async () => {
                        await this.ordersService.orderAction(id, OrderStatusesEnum.CANCELLED);
                        this.updateData.emit();
                    },
                },
            ],
        });

        await alert.present();
    }

    public async onPay(it: IOrders, selectedMethod?: IPaymentsItem): Promise<void> {
        if (it?.order_status_id === OrderStatusesEnum.IN_PROGRESS || it?.order_status_id === OrderStatusesEnum.ACCEPTED) {
            const sendData: IPaymentData = {
                name: it.name,
                phone: it.phone,
                amount: this.payService.toCents(it.price as number),
                currency: 'eur',
                master_id: it.master_id,
            };

            if (selectedMethod?.payment_method) {
                sendData.payment_method = selectedMethod.payment_method;
            }

            const res = await this.payService.paymentSheet(sendData);

            if (res) {
                await this.ordersService.orderAction(it.id, OrderStatusesEnum.PAID);

                this.updateData.emit();
            }
        }
    }

    public onIonInfinite(ev, page: number, pages?: number): void {
        if (page >= pages) {
            void timeoutUtil().then(() => {
                void (ev as InfiniteScrollCustomEvent).target.complete();
            });
            return;
        }

        this.loadDataPush.emit({ ev, page });
    }

    public async paymentSelectionChange(selectedMethod: IPaymentsItem): Promise<void> {
        this.selectedFruits = selectedMethod.value;
        if (selectedMethod.value === SelectedPaymentEnum.CASH) {
            await this.modalCtrl.dismiss();
        } else if (selectedMethod.value === SelectedPaymentEnum.CARD) {
            await this.modalCtrl.dismiss();
            await this.onPay(this.selectedItem);
        } else if (selectedMethod.payment_method) {
            await this.modalCtrl.dismiss();
            await this.onPay(this.selectedItem, selectedMethod);
        } else {
            await this.modalCtrl.dismiss();
            await this.onPay(this.selectedItem);
        }
    }

    public async onMenu(order: IOrders): Promise<void> {
        const buttons = await this.ordersActionService.getOrderActions(order);

        // Добавьте необходимые обработчики к кнопкам здесь
        buttons.forEach(button => {
            if (button.data?.action === 'edit') {
                button.handler = () => this.ordersActionService.goToEditOrder(order);
            } else if (button.data?.action === 'pay') {
                button.handler = () => this.setOpen(true, order);
            } else if (button.data?.action === 'delete') {
                button.handler = async () => await this.onCancel(order.id);
            } else if (button.text === this.translate.instant('ACCEPT')) {
                button.handler = async () => {
                    await this.ordersService.orderAction(order.id, OrderStatusesEnum.ACCEPTED);
                    this.updateData.emit();
                };
            }
        });

        const actionSheet = await this.actionSheetCtrl.create({
            header: this.translate.instant('ORDER_ACTIONS'),
            subHeader: order.title,
            mode: 'ios',
            buttons,
        });

        await actionSheet.present();
    }

    protected readonly UserRolesEnum = UserRolesEnum;
    protected readonly OrderStatusesEnum = OrderStatusesEnum;
}
