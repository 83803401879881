<ion-header mode="ios">
  <hb-header title="LANGUAGE" backUrl="/account"></hb-header>
</ion-header>
<ion-content>
  <ion-list class="ion-margin-bottom">
    <ion-radio-group [value]="selected" name="radio-group">
      @for (item of list; track item) {
        <ion-item lines="none" mode="md">
          <ion-radio
            color="pink"
            justify="start"
            name="{{ item | lowercase }}"
            value="{{ item | lowercase }}"
            (click)="select(item)"
            labelPlacement="end"
            slot="start"
            >
            <span class="padding-l-6">
              {{ item === langs.Ru ? 'Русский' : 'English' }}
            </span>
          </ion-radio>
        </ion-item>
      }
    </ion-radio-group>
  </ion-list>
</ion-content>
