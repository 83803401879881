import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthServiceConfig, AUTH_SERVICE_CONFIG, AuthService } from './auth.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthGuard } from './auth.guard';

@NgModule({ declarations: [], imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AuthModule {
    static forRoot(config: AuthServiceConfig): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: AUTH_SERVICE_CONFIG,
                    useValue: config,
                },
                AuthService,
                AuthGuard,
            ],
        };
    }
}
