import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEmptyData, isEmptyValue } from '@solar/core/src/services/utils';

@Pipe({
    name: 'serverTranslate',
    standalone: true,
})
export class ServerTranslatePipe implements PipeTransform {
    constructor(private readonly translate: TranslateService) {}

    public transform(
        obj: any, // Добавлен тип `any` для лучшего контроля типов
        key: string = 'title',
    ): string {
        // Проверяем, существует ли объект и содержит ли он нужные данные.
        if (!obj || typeof obj !== 'object') {
            return '';
        }

        // Получаем текущий язык из TranslateService.
        const currLang = this.translate.currentLang || 'en'; // Добавлено значение по умолчанию

        // Проверяем, существует ли ключ с текущим языком в объекте.
        const translationKey = `${key}_${currLang}`;

        if (obj.hasOwnProperty(translationKey) && !isEmptyValue(obj[translationKey]) && !isEmptyData(obj[translationKey])) {
            return obj[translationKey];
        } else {
            // Возвращаем значение по умолчанию, если ключ с переводом не существует или пустой.
            return obj[key] || '';
        }
    }
}
