import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BatchDto } from '../../../interfaces';

@Injectable({
    providedIn: 'root',
})
export class BatchService {
    constructor(private readonly http: HttpClient) {}

    public batch(requests): Observable<BatchDto[]> {
        return this.http.post<BatchDto[]>('batch', { requests });
    }
}
