import { Injectable } from '@angular/core';
import { categoriesPageName, categoryPageName } from './selectors';
import { PagesEffects } from '../pages';
import { FormEffects } from '../forms';
import { map } from 'rxjs/operators';
import { CategoriesService } from '../../services/rest';

export const addCategoryFormName = 'addCategory';
export const editCategoryFormName = 'editCategory';

@Injectable()
export class CategoriesEffects {
    public readonly $addCategory = this.formEffects.getSendFormEffect(addCategoryFormName, formState => {
        return this.categoriesService.addCategory(formState.formData);
    });

    public readonly $editCategory = this.formEffects.getSendFormEffect(editCategoryFormName, formState => {
        return this.categoriesService.editCategory(formState.formData);
    });
    public readonly loadCategories$ = this.pagesEffects.getLoadPageEffect(categoriesPageName, () =>
        this.categoriesService.loadCategories().pipe(map(res => ({ data: res.data }))),
    );

    public readonly loadCategory$ = this.pagesEffects.getLoadPageEffect(categoryPageName, ({ reqData }) => {
        return this.categoriesService.loaCategory(reqData.id).pipe(map(res => ({ data: res })));
    });

    // loadPushOffers$ = this.pagesEffects.getLoadPagePushEffect(offersPageName, ({ page, filters }) =>
    //     this.offersService.getAll({ page, filters }).pipe(map(res => ({ ...res, data: res.items })))
    // );
    //
    // deleteOffer$ = this.formEffects.getSendFormEffect(deleteOffer, ({ id }) => {
    //     return this.offersService.delete(id);
    // });

    constructor(
        private readonly pagesEffects: PagesEffects,
        private readonly formEffects: FormEffects,
        private readonly categoriesService: CategoriesService,
    ) {}
}
