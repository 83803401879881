import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthService } from '../../auth/auth.service';
import { FormEffects, loginFormName, SendForm } from '../../store';
import { IAppState, ToastService } from '../../';

export const signUpFormName = 'signUpFormName';

@Injectable()
export class SignUpEffects {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly store: Store<IAppState>,
        private readonly formEffects: FormEffects,
        private readonly toastService: ToastService,
    ) {}

    public readonly $signUp = this.formEffects.getSendFormEffect(signUpFormName, formState => {
        return this.authService.signUp(formState.formData).pipe(
            tap(res => {
                void this.toastService.presentToast(res.message).then();

                this.store.dispatch(
                    SendForm({
                        formName: loginFormName,
                        formState: {
                            formData: {
                                method: formState.formData.code + formState.formData.phone,
                                password: formState.formData.password,
                            },
                        },
                    }),
                );

                void this.router.navigate(['/login']);
            }),
        );
    });
}
