import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '../../auth/auth.service';
import { signal } from '@angular/core';
import { WritableSignal } from '@angular/core';

export interface IOnlineUser {
    id: number;
}

@Injectable({
    providedIn: 'root',
})
export class OnlineWsService {
    protected online: WritableSignal<IOnlineUser[]> = signal([]);

    constructor(
        private readonly socket: Socket,
        private readonly authService: AuthService,
    ) {
        this.allOnlineUsers();
    }

    public connectUser(): void {
        this.socket.emit('add_online_user', this.authService?.principal?.id);
    }

    public allOnlineUsers(): void {
        this.socket.on('online_users', action => {
            this.online.set(action);
        });
    }

    public isOnline(id: number): boolean {
        // if (this.authService?.principal?.id === id) {
        //     return false;
        // }

        return this.online().some(it => it.id === id);
    }

    public get onlineUsers(): IOnlineUser[] {
        return this.online();
    }

    public disconnectUser(): void {
        this.socket.emit('delete_online_user', this.authService?.principal?.id);
    }
}
