import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';

@Pipe({
    name: 'dateAgo',
    pure: true,
    standalone: true,
})
export class DateAgoPipe implements PipeTransform {
    constructor(private readonly translationService: TranslateService) {}

    public transform(value: any): any {
        if (value) {
            // Преобразуем значение в ISO строку, если оно таковым не является
            const date = parseISO(value);
            const currentLang = this.translationService.currentLang;

            const locale = currentLang === 'ru' ? ru : enUS;
            // Используем date-fns для получения времени, прошедшего с момента даты
            const timeAgo = formatDistanceToNow(date, {
                addSuffix: true,
                locale: locale,
            });

            // Переводим строку через TranslateService
            return this.translationService.instant(timeAgo);
        }
        return value;
    }
}
