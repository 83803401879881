import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { JsonPipe } from '@angular/common';

@Component({
    selector: 'hb-stars',
    templateUrl: './stars.component.html',
    styleUrls: ['./stars.component.scss'],
    standalone: true,
    imports: [IonicModule, JsonPipe],
})
export class StarsComponent implements OnInit {
    @Input() public average_rating: string;
    @Input() public review_count: string;
    public starsArray: string[] = [];

    constructor() {}

    public ngOnInit(): void {
        this.updateStarsArray();
    }

    public updateStarsArray(): void {
        if (this.average_rating) {
            this.average_rating = parseFloat(this.average_rating).toFixed(1);
            const filledStars = Math.floor(Number(this.average_rating));
            const emptyStars = 5 - filledStars;

            this.starsArray = Array(filledStars).fill('star');
            this.starsArray = this.starsArray.concat(Array(emptyStars).fill('star-outline'));
        }
    }
}
