import { Injectable } from '@angular/core';
import { FormEffects, PagesEffects } from '../../store';
import { PaymentsService } from '../../services';

export const getPaymentsList = 'getPaymentsList';
export const payoutsToCard = 'payoutsToCard';

@Injectable()
export class PaymentsEffects {
    constructor(
        private readonly formEffects: FormEffects,
        private readonly pagesEffects: PagesEffects,
        private readonly paymentsService: PaymentsService
    ) {}

    public readonly getPaymentsList$ = this.pagesEffects.getLoadPageEffect(getPaymentsList, ({ reqData }) =>
        this.paymentsService.getPaymentsList(reqData)
    );

    public readonly payouts$ = this.formEffects.getSendFormEffect(payoutsToCard, ({ formData }) =>
        this.paymentsService.payoutsToDestination(formData)
    );
}
