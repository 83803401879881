import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LangsEnum } from '@solar/enums';
import { IAppState } from '@solar/interfaces';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { LowerCasePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from '../../stateless/';

@Component({
    selector: 'hb-lang-radio-list',
    templateUrl: './lang-radio-list.component.html',
    styleUrls: ['./lang-radio-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IonicModule, LowerCasePipe, TranslateModule, HeaderComponent],
})
export class LangRadioListComponent implements OnInit {
    public selected: string;

    public readonly langs: typeof LangsEnum = LangsEnum;
    public readonly list: LangsEnum[] = Object.entries(LangsEnum).map(([, value]) => value);

    constructor(
        private readonly store: Store<IAppState>,
        private readonly translateService: TranslateService,
        private readonly ch: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.setSelectedLang();
    }

    public select(lang: string): void {
        this.translateService.setDefaultLang(lang);
        this.translateService.use(lang);

        localStorage.setItem('lang', lang);
    }

    private setSelectedLang(): void {
        this.selected = this.translateService.getDefaultLang();

        this.ch.detectChanges();
    }
}
